import { graphql, useStaticQuery } from "gatsby"

interface StripeItem {
  _uid: string,
  uuid: string,
  name: string,
  line_item_label: string,
  amount: number,
  stripe_price_id: string,
  preferred_dropdown_order?: number
}
function useStripeItems() {
  const results = useStaticQuery(graphql`
    query GetStripeItems {
      allStoryblokEntry(
        filter: {field_component: {eq: "stripe_item"}}
      ) {
        edges {
          node {
            full_slug
            uuid
            content
          }
        }
      }
    }
  `)
  let types: Array<StripeItem> = []

  results.allStoryblokEntry.edges.map((edge:any)=>{
    let data = JSON.parse(edge.node.content)
    types.push({
      _uid: data._uid,
      uuid: edge.node.uuid,
      name: data.name,
      line_item_label: data.line_item_label,
      amount: parseFloat(data.amount) || 0,
      stripe_price_id: data.stripe_price_id
    })
  })

  return types
}

export { StripeItem }
export default useStripeItems