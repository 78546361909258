import React from "react"
import { Richtext } from "storyblok-js-client"

import { resolveRichText } from "../../../utils/richText"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import DynamicCTA from "../../../components/cta/DynamicCta"
import { normalizeURL } from "../../internal/storyblokImage/StoryblokImage"
// @ts-ignore
import * as styles from "./BannerArea.module.css"

interface BannerAreaPanel extends Panel {
  headline: Richtext,
  image: StoryblokRef.Image,

  cta_items: Array<StoryblokRef.Blok>
}
interface BannerAreaProps {
  blok: BannerAreaPanel
}
function BannerArea({ blok }:BannerAreaProps):JSX.Element {
  return (
    <PanelWrapper blok={blok}>
      <section className={`event_banner_area ${styles.wrapper}`}>
        {blok.image && blok.image.filename ? <div className={`parallax-effect ${styles.parallaxSection}`} style={{backgroundImage: `url(${normalizeURL(blok.image.filename)}1920x0)`, backgroundSize: "cover"}}></div> : null}
        <div className="container">
          <div className="event_banner_content">
            <div className="round wow zoomIn" data-wow-delay="0.2s"></div>
            {blok.headline ? <div className={`wow fadeInUp ${styles.content}`} data-wow-delay="0.6s" dangerouslySetInnerHTML={resolveRichText(blok.headline)} /> : null}
            <div>
              {blok.cta_items ? blok.cta_items.map((item)=>{
                return <span className={`wow fadeInRight ${styles.cta_item}`} data-wow-delay="0.9s" key={item._uid}><DynamicCTA key={item._uid} blok={item} /></span>
              }) : null}
            </div>
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

export default BannerArea