import React from "react"
import { Richtext } from "storyblok-js-client"

import StoryblokImage from "../../internal/storyblokImage/StoryblokImage"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../utils/richText"
// @ts-ignore
import * as styles from "./AboutArea.module.css"

interface AboutAreaPanel extends Panel {
  content: any,
  image: StoryblokRef.Image,
  left_image: StoryblokRef.Image,
  left_content: Richtext,
  right_image: StoryblokRef.Image,
  right_content: Richtext,
}
interface AboutAreaPanelProps {
  blok: AboutAreaPanel
}

var breakpoints = {
  "-991":"400x0",
  "-1200":"456x0",
  "1201-":"576x0"
}

function AboutArea({ blok }:AboutAreaPanelProps):JSX.Element {
  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6">
              <div className="event_about_img">
                {blok.image && blok.image.filename ? <div className={`wow fadeInRight ${styles.image}`} data-wow-delay="0.2s"><StoryblokImage image={blok.image} sizes={breakpoints} /></div> : null}
                {/* <div className={`about_bg ${styles.image_bg}`}></div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="event_about_content">
                {blok.content ? <div className="wow fadeInUp" dangerouslySetInnerHTML={resolveRichText(blok.content)} /> : null}
                <div className="row">
                  <div className="col-6">
                    <div className="event_about_item wow fadeInUp" data-wow-delay="0.2s">
                      {blok.left_image && blok.left_image.filename ? <StoryblokImage image={blok.left_image} /> : null}
                      {blok.left_content ? <div dangerouslySetInnerHTML={resolveRichText(blok.left_content)} /> : null}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="event_about_item wow fadeInUp" data-wow-delay="0.4s">
                      {blok.right_image && blok.right_image.filename ? <StoryblokImage image={blok.right_image} /> : null}
                      {blok.right_content ? <div dangerouslySetInnerHTML={resolveRichText(blok.right_content)} /> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

export default AboutArea