import { graphql, useStaticQuery } from "gatsby"

interface ConferenceSession {
  _uid: string,
  uuid: string,
  title: string,
}
function useConferenceSessions() {
  const results = useStaticQuery(graphql`
    query GetCurrentSessions {
      allStoryblokEntry(
        filter: {field_component: {eq: "conference_session"}}
        limit: 9
      ) {
        edges {
          node {
            full_slug
            uuid
            content
          }
        }
      }
    }
  `)
  let sessions: Array<ConferenceSession> = []

  results.allStoryblokEntry.edges.map((edge:any)=>{
    let data = JSON.parse(edge.node.content)
    sessions.push({
      _uid: data._uid,
      uuid: edge.node.uuid,
      title: data.title,
    })
  })

  return sessions
}

export { ConferenceSession }
export default useConferenceSessions