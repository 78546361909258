import React from "react"
// @ts-ignore
import Slider from "react-slick"
import { Richtext } from "storyblok-js-client"

import EventTeamItem from "../../internal/eventTeamItem/EventTeamItem"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../utils/richText"
// @ts-ignore
// import * as styles from "./EventSpeakersPanel.module.css"

interface EventSpeaker {
  _uid: string,
  name: string,
  description: string,
  image: StoryblokRef.Image,
  link_to: StoryblokRef.Link,
}
interface EventSpeakersPanel extends Panel {
  headline: Richtext,
  speakers: Array<EventSpeaker>
}

interface EventSpeakersPanelProps {
  blok: EventSpeakersPanel
}
function EventSpeakers({ blok }:EventSpeakersPanelProps):JSX.Element {
  // https://react-slick.neostack.com/docs/api
  const settings = {
    dots: true,
    infinite: true,
    speed: 750,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  }

  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="container">
          <div className="hosting_title security_title text-center">
            <div className="wow fadeInUp" data-wow-delay="0.2s" dangerouslySetInnerHTML={resolveRichText(blok.headline)} />
          </div>
          <Slider className="event_team_slider" {...settings}>
            {blok.speakers ? blok.speakers.map((speaker)=>{
              return <EventTeamItem key={speaker._uid} image={speaker.image} name={speaker.name} post={speaker.description} link_to={speaker.link_to} />
            }) : null}
          </Slider>
        </div>
      </section>
    </PanelWrapper>
  )
}

export default EventSpeakers