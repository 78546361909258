import React, { useEffect, useState } from "react"

// @ts-ignore
import * as styles from "./SponsorRegistrationCompleteSummaryPanel.module.css"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { FormatCurrency } from "../../../utils/utils"

interface SponsorRegistrationCompletePanel extends Panel {}

interface SponsorRegistrationCompleteProps {
  blok: SponsorRegistrationCompletePanel
}
function SponsorRegistrationCompleteSummaryPanel({ blok }:SponsorRegistrationCompleteProps):JSX.Element {
  const [isLoading,setIsLoading] = useState<boolean>(true)
  const [registrationDetails,setRegistrationDetails] = useState<SponsorRegistrationRecord>()

  function fetchSponsorData() {
    let urlParams = new URLSearchParams(window.location.search)
    const customer_reference_id = urlParams.get('c')


    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/forms?c=${customer_reference_id}`, {
      method: 'GET',
    })
      .then((response)=>{
        response.text().then((responseString)=>{
          const d = JSON.parse(responseString)

          setRegistrationDetails(d.data.data)
          setIsLoading(false)
        })
        .catch((err)=>{
          console.error(err)
        })
      })
      .catch((err)=>{
        console.error(err)
      })
  }

  useEffect(()=>{
    fetchSponsorData()
  },[])

  return (
    <PanelWrapper blok={blok}>
      {isLoading ? (
        <div className={`container ${styles.loading}`}>
          Please wait while your order summary is loaded.
        </div>
      ) : (
        <div className="container">
          <h3>Your contact information</h3>
          <div>{`${registrationDetails?.firstName} ${registrationDetails?.lastName}`}</div>
          <div>Affiliation: {registrationDetails?.affiliation}</div>
          <div>{`${registrationDetails?.address1} ${registrationDetails?.address2}`}</div>
          <div>{`${registrationDetails?.city}, ${registrationDetails?.state}, ${registrationDetails?.zip}, ${registrationDetails?.country}`}</div>
          <div>Telephone: {registrationDetails?.telephone}</div>
          <div>Email: {registrationDetails?.email}</div>
          <br/>
          <h3>Sponsor Information</h3>
          <div>Sponsor Name: {`${registrationDetails?.sponsor_name}`}</div>
          <div>Sponsor Website: {`${registrationDetails?.sponsor_website}`}</div>
          <div>Sponsor Options: </div>
          {registrationDetails?.purchase_items?.map((item)=>{return (
            <div key={item.key} style={{paddingLeft: 10}}>
              {`${item.label} (${item.quantity} @ ${FormatCurrency(item.price)})`}
            </div>
          )})}
          <div>{`Amount Charged: ${FormatCurrency(registrationDetails?.amount_charged || 0)}`}</div>
        </div>
      )}
    </PanelWrapper>
  )
}

export default SponsorRegistrationCompleteSummaryPanel