import React from "react"
import { Richtext } from "storyblok-js-client"

import { resolveRichText } from "../../../utils/richText"
import EventTimer from "../../internal/eventTimer/EventTimer"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"


interface EventCounterPanel extends Panel {
  text: Richtext,
  date: string
}
interface EventCounterPanelProps {
  blok: EventCounterPanel
}
function EventCounter({blok}:EventCounterPanelProps) {
  const message = resolveRichText(blok.text)
  const expireDate = blok.date ? new Date(blok.date.replace(/-/g, "/")) : null
  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="event_text wow fadeInLeft" dangerouslySetInnerHTML={message}></div>
            </div>
            <div className="col-lg-8">
              <div className="event_countdown wow fadeInRight">
                <div className="event_counter"><EventTimer time={expireDate} /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

export default EventCounter