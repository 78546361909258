import React from 'react'
import { Richtext } from 'storyblok-js-client'

import { resolveRichText } from '../../../utils/richText'
import StoryblokImage from '../storyblokImage/StoryblokImage'
// @ts-ignore
import * as styles from "./EventGalleryItem.module.css"


interface GalleryItem {
  _uid?: string,
  image: StoryblokRef.Image,
  content: Richtext
}

var breakpoints = {
  "1-":"330x479"
}


function EventGalleryItem({ image,  content }:GalleryItem):JSX.Element {

  return (
    <div className="col portfolio_item br ux p0">
      <div className={`portfolio_img ${styles.item_image}`}>
        {image ? <StoryblokImage image={image} sizes={breakpoints}/> : null}
        <div className={styles.hover_content}>
          {content ? <div className="portfolio-description leaf" dangerouslySetInnerHTML={resolveRichText(content)} /> : null} 
        </div>
      </div>
    </div>
  )
}

export { GalleryItem }
export default EventGalleryItem