import React, { useState, useEffect } from "react";

// @ts-ignore
import * as styles from "./EventTimer.module.css"

interface TimeObject {
  days:number,
  hours:number,
  minutes:number,
  seconds:number
}
interface EventTimerProps {
  time:Date | null // Date ( seconds )
}
function EventTimer({time}:EventTimerProps):JSX.Element {
  const [timeLeft,setTimeLeft] = useState(calcTime())

  function calcTime():number {
    if(time) {
      const now = new Date().getTime()
      return time.getTime() - now
    }
    return 0
  }

  useEffect(()=>{
    let intervalId = setInterval(()=>{
      setTimeLeft(calcTime())
    }, 1000)
    return ()=>{
      clearTimeout(intervalId)
    }
  })

  let stats:TimeObject = {
    days: Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
    hours: Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((timeLeft % (1000 * 60)) / 1000)
  }

  let days, hours, minutes, seconds
  if(stats.days < 0) {
    days = "0"
  } else {
    days = stats.days < 10 ? "0" + `${stats.days}` : `${stats.days}`
  }
  if(stats.hours < 0) {
    hours = "0"
  } else {
    hours = stats.hours < 10 ? "0" + `${stats.hours}` : `${stats.hours}`
  }
  if(stats.minutes < 0) {
    minutes = "0"
  } else {
    minutes = stats.minutes < 10 ? "0" + `${stats.minutes}` : `${stats.minutes}`
  }
  if(stats.seconds < 0) {
    seconds = "0"
  } else {
    seconds = stats.seconds < 10 ? "0" + `${stats.seconds}` : `${stats.seconds}`
  }


  return (
    <div id="countdown" className="row">
      <div className="col-sm-12 col-md-6 col-lg-3">
          <div className={`${styles.box} ${styles.colorOne}`}>
              <div className="text">
                  <p id="day">{days}</p>
                  <span className="text">Days</span>
              </div>
          </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3">
          <div className={`${styles.box} ${styles.colorTwo}`}>
              <div className="text">
                  <p id="hour">{hours}</p>
                  <span className="text">Hours</span>
              </div>
          </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3">
          <div className={`${styles.box} ${styles.colorThree}`}>
              <div className="text">
                  <p id="minute">{minutes}</p>
                  <span className="text">Minutes</span>
              </div>
          </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-3">
          <div className={`${styles.box} ${styles.colorFour}`}>
              <div className="text">
                  <p id="second">{seconds}</p>
                  <span className="text">Seconds</span>
              </div>
          </div>
      </div>
    </div>
  )
}

export default EventTimer;
