import React from "react"
import { Richtext } from "storyblok-js-client"

import { resolveRichText } from "../../../utils/richText"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
// @ts-ignore
import Map from "../../internal/map/Map"

interface MarkerItem {
  latitude: number,
  longitude: number
}
interface MapWithOverlayPanel extends Panel {
  content: Richtext,
  latitude: string,
  longitude: string
}
interface MapWithOverlayProps {
  blok: MapWithOverlayPanel
}
function MapWithOverlay({ blok }:MapWithOverlayProps):JSX.Element {
  const markers:Array<MarkerItem> = []
  if(blok.latitude && blok.longitude) {
    markers.push({
      latitude: parseFloat(blok.latitude),
      longitude: parseFloat(blok.longitude)
    })
  }

  return (
    <PanelWrapper blok={blok}>
      <section className="event_location_area">
        {markers && markers.length > 0 ? <Map markers={markers} /> : null}
        <div className="container">
          <div className="event_location">
            <div className="contact_info_item" dangerouslySetInnerHTML={resolveRichText(blok.content)} />
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

export default MapWithOverlay