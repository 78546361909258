
function BackgroundKeyToColor(key:string):string {
  switch(key) {
    case "white":
      return "#fff"
    case "light":
      return "#f4f4f4"
    default:
      return "transparent"
  }
}

function HeightSizeKeyToBarHeight(key: string):string {
  switch(key) {
    case "75_percent":
      return "75%"
    case "50_percent":
      return "50%"
    case "33_percent":
      return "33%"
    case "25_percent":
      return "25%"
    case "10_percent":
      return "10%"
    default:
      return "50%"
  }
}

function GeneralSizeKeyToPaddingNumber(key: string):number {
  switch(key) {
    case "small":
      return 30
    case "medium":
      return 60
    case "large":
      return 90
    default:
      return 1
  }
}

export { BackgroundKeyToColor, HeightSizeKeyToBarHeight, GeneralSizeKeyToPaddingNumber }