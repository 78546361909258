import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import EventSponsorLogo from "../../internal/eventSponsorLogo/EventSponsorLogo"
import { resolveRichText } from "../../../utils/richText"
import DynamicCTA from "../../../components/cta/DynamicCta"
import useSponsorshipLevels from "../../../hooks/useSponsorshipLevels"
import useSponsors, { Sponsor } from "../../../hooks/useSponsors"

interface SponsorAreaPanel extends Panel {
  headline: Richtext,
  cta_items: Array<CTA>
}
interface SponsorAreaPanelProps {
  blok: SponsorAreaPanel
}
function SponsorArea({ blok }:SponsorAreaPanelProps):JSX.Element {
  const sponsorshipLevels = useSponsorshipLevels()
  const sponsors = useSponsors()
  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="container">
          <div className="hosting_title security_title text-center" dangerouslySetInnerHTML={resolveRichText(blok.headline)} />
          <div className="event_sponser_inner">
            {sponsorshipLevels.map((sponsorship)=>{
              let sponsorList = sponsors.filter((sponsor)=>{
                return sponsor.sponsorship_level === sponsorship.uuid
              })

              return (
                <SponsorRowComponent key={sponsorship._uid} label={sponsorship.name} sponsors={sponsorList} />
              )
            })}
            <div className="event_sponser_item text-center cta-buttons-inline">
              {blok.cta_items ? blok.cta_items.map((item)=>{
                return <DynamicCTA key={item._uid} blok={item} />
              }) : null}
            </div>
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

interface SponsorRow {
  label: string,
  sponsors: Array<Sponsor>
}
function SponsorRowComponent({label, sponsors}:SponsorRow):JSX.Element {
  let orderedList = sponsors.sort((a:Sponsor,b:Sponsor)=>{
    if(a.sponsorship_panel_preferred_order === b.sponsorship_panel_preferred_order) {
      return 0
    } else if(a.sponsorship_panel_preferred_order) {
      if(b.sponsorship_panel_preferred_order) {
        return a.sponsorship_panel_preferred_order - b.sponsorship_panel_preferred_order
      }
      return -1
    }
    return 1
  })
  return (
    <div className="event_sponser_item">
      <div className="row">
        <div className="col-lg-3 col-md-4">
          {label ? <h4 className="h_head sponser_title">{label}</h4> : null}
        </div>
        <div className="col-lg-9 col-md-8">
          <div className="row">
            {orderedList.map((sponsor)=>(
              <EventSponsorLogo key={sponsor._uid} image={sponsor.image} link={sponsor.link} newWindow={true} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SponsorArea