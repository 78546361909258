import React from "react"
import { Richtext } from "storyblok-js-client"

import StoryblokLink from "../../internal/storyblokLink/StoryblokLink"
import StoryblokImage from "../../internal/storyblokImage/StoryblokImage"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../utils/richText"
// @ts-ignore
import * as styles from "./EventPromotionArea.module.css"

interface EventPromotionPanel extends Panel {
  headline: Richtext,
  image: StoryblokRef.Image,
  content: Richtext,

  type_label: string,
  date: string,
  title: string,
  cta_label: string,
  cta_link: StoryblokRef.Link
}
interface EventPromotionAreaProps {
  blok: EventPromotionPanel
}

var breakpoints = {
  "-991":"696x0",
  "-1200":"936x0",
  "1201-":"1176x0"
}

function EventPromotionArea({ blok }:EventPromotionAreaProps):JSX.Element {
  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="container">
          <div className="hosting_title security_title text-center">
            {blok.headline ? <div className="wow fadeInUp" data-wow-delay="0.2s" dangerouslySetInnerHTML={resolveRichText(blok.headline)} /> : null}
          </div>
          <div className="event_promotion_inner">
            {blok.image ? (
              <div className={`event_img ${styles.image_wrapper}`}>
                <StoryblokImage image={blok.image} sizes={breakpoints}/>
              </div>
            ) : null}
            <div className="row event_promotion_info align-items-center">
              <div className="col-md-6">
                <div className="e_promo_text wow fadeInDown">
                  <div className="d-flex">
                    {blok.type_label ? <span className={`promo_tag ${styles.tag}`}>{blok.type_label}</span> : null}
                    {blok.date ? <span className={`date ${styles.date}`}>{blok.date}</span> : null}
                  </div>
                  {blok.title ? <h3>{blok.title}</h3> : null}
                  {blok.cta_label ? <StoryblokLink text={blok.cta_label} withClass="parc-btn outline orange" link={blok.cta_link}  /> : null}
                </div>
              </div>
              <div className="col-md-6" dangerouslySetInnerHTML={resolveRichText(blok.content)} />
            </div>
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

export default EventPromotionArea