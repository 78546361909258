import React from "react"

interface AsideWrapperProps {
  children: Array<any>
}
function AsideWrapper({children}:AsideWrapperProps):JSX.Element {
  let leftChild:any,
      rightChild:any
  
  children.forEach((child)=>{
    if(child.type === AsideLeft) {
      leftChild = child
    }
    if(child.type === AsideRight) {
      rightChild = child
    }
  })

  return (
    <div className="container">
      <div className="row flex-row-reverse">
        <div className="col-lg-4 pl_70">
          {rightChild}
        </div>
        <div className="col-lg-8">
          {leftChild}
        </div>
      </div>
    </div>
  )
}

function AsideLeft({children}:any):JSX.Element {
  return (
    <>
      {children}
    </>
  )
}
function AsideRight({children}:any):JSX.Element {
  return (
    <>
      {children}
    </>
  )
}


export { AsideLeft, AsideRight }
export default AsideWrapper