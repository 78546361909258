import React from 'react';

import PanelWrapper from '../../internal/panelWrapper/PanelWrapper';
import EventGalleryItem, { GalleryItem } from "../../internal/eventGalleryItem/EventGalleryItem"


interface EventGalleryPanel extends Panel {
  items: Array<GalleryItem>
}
interface EventGalleryPanelProps {
  blok: EventGalleryPanel
}
function EventGallery({ blok }:EventGalleryPanelProps):JSX.Element {
  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="row m0 portfolio_gallery event_gallery">
          {blok.items.map((galleryItem:GalleryItem)=>{
            return <EventGalleryItem key={galleryItem._uid} image={galleryItem.image} content={galleryItem.content} />
          })}
        </div>
      </section>
    </PanelWrapper>
  )
}

export default EventGallery