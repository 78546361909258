import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../utils/richText"

interface RichTextPanel extends Panel {
  content: Richtext,
  text_styles: Array<string>
}
interface RichTextPanelProps {
  blok: RichTextPanel
}
function RichText({ blok }:RichTextPanelProps):JSX.Element {
  return (
    <PanelWrapper blok={blok}>
      <div className={`container${blok.text_styles && blok.text_styles.length > 0 ? ` ${blok.text_styles.join(' ')}` : ""}`}>
        <div dangerouslySetInnerHTML={resolveRichText(blok.content)} />
      </div>
    </PanelWrapper>
  )
}

export default RichText