import React from "react"
// @ts-ignore
import SbEditable from "storyblok-react"

// Panels
import EventCounterPanel from "./panels/eventCounter/EventCounter"
import AboutAreaPanel from "./panels/aboutArea/AboutArea"
import EventSpeakersPanel from "./panels/eventSpeakers/EventSpeakersPanel"
import EventFeaturesPanel from "./panels/eventFeatures/EventFeaturesPanel"
import EventPromotionArea from "./panels/eventPromotionArea/EventPromotionArea"
import EventSchedulePanel from "./panels/eventSchedule/EventSchedulePanel"
import EventPriceAreaPanel from "./panels/eventPriceArea/EventPriceArea"
import FactAreaPanel from "./panels/factArea/FactAreaPanel"
import EventGalleryPanel from "./panels/eventGallery/EventGalleryPanel"
import SponsorAreaPanel from "./panels/sponsorArea/SponsorArea"
import BannerArea from "./panels/bannerArea/BannerArea"
import HeroPanel from "./panels/hero/Hero"
import PriceColumnPanel from "./panels/priceColumn/PriceColumnPanel"
import MapWithOverlay from "./panels/mapWithOverlay/MapWithOverlay"
import RichText from "./panels/richText/RichText"
import ImageText from "./panels/imageText/ImageText"
import AsideText from "./panels/asideText/AsideText"
import OnsiteRegistrationCompleteSummaryPanel from "./panels/onsiteRegistrationCompleteSummaryPanel/OnsiteRegistrationCompleteSummaryPanel"
import OnlineRegistrationCompleteSummaryPanel from "./panels/onlineRegistrationCompleteSummaryPanel/OnlineRegistrationCompleteSummaryPanel"
import SponsorRegistrationCompleteSummaryPanel from "./panels/sponsorRegistrationCompleteSummaryPanel/SponsorRegistrationCompleteSummaryPanel"
import ExhibitorRegistrationCompleteSummaryPanel from "./panels/exhibitorRegistrationCompleteSummaryPanel/ExhibitorRegistrationCompleteSummaryPanel"
import ALaCarteRegistrationCompleteSummaryPanel from "./panels/alACarteRegistrationCompleteSummaryPanel/AlACarteRegistrationCompleteSummaryPanel"

// Forms
import RegistrationForm from "./forms/RegistrationForm"
import OnlineRegistrationForm from "./forms/onlineRegistrationForm/OnlineRegistrationForm"
import OnsiteRegistrationForm from "./forms/onsiteRegistrationForm/OnsiteRegistrationForm"
import SponsorshipForm from "./forms/SponsorshipForm/SponsorshipForm"
import ExhibitorForm from "./forms/ExhibitorForm/ExhibitorForm"
import AbstractForm from "./forms/AbstractForm/AbstractForm"
import AlACarteForm from "./forms/AlACarteForm/AlACarteForm"
import AbstractRegistrationCompleteSummary from "./panels/abstractRegistrationCompleteSummaryPanel/AbstractRegistrationCompleteSummaryPanel"

const Components:any = {
  // Panels
  "event_counter_panel": EventCounterPanel,
  "about_area_panel": AboutAreaPanel,
  "event_speakers_panel": EventSpeakersPanel,
  "event_features_panel": EventFeaturesPanel,
  "event_promotion_panel": EventPromotionArea,
  "conference_schedule_panel": EventSchedulePanel,
  "event_price_area_panel": EventPriceAreaPanel,
  "fact_area_panel": FactAreaPanel,
  "event_gallery_panel": EventGalleryPanel,
  "sponsor_area_panel": SponsorAreaPanel,
  "banner_area_panel": BannerArea,
  "hero_panel": HeroPanel,
  "price_column_panel": PriceColumnPanel,
  "map_with_overlay": MapWithOverlay,
  "rich_text_panel": RichText,
  "image_text_panel": ImageText,
  "aside_text_panel": AsideText,
  "onsite_registration_complete_summary_panel": OnsiteRegistrationCompleteSummaryPanel,
  "online_registration_complete_summary_panel": OnlineRegistrationCompleteSummaryPanel,
  "sponsor_registration_complete_summary_panel": SponsorRegistrationCompleteSummaryPanel,
  "exhibitor_registration_complete_summary_panel": ExhibitorRegistrationCompleteSummaryPanel,
  "a_la_carte_registration_complete_summary_panel": ALaCarteRegistrationCompleteSummaryPanel,
  "abstract_registration_complete_summary_panel": AbstractRegistrationCompleteSummary,

  // Forms
  "registration_form": RegistrationForm,
  "online_registration_form": OnlineRegistrationForm,
  "onsite_registration_form": OnsiteRegistrationForm,
  "abstract_form": AbstractForm,
  "sponsorship_form": SponsorshipForm,
  "exhibitor_form": ExhibitorForm,
  "a_la_carte_form": AlACarteForm
}

interface DynamicComponentProps {
  blok: any
}
const DynamicComponent = ({ blok }:DynamicComponentProps):JSX.Element => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]

    return (<SbEditable content={blok}><Component blok={blok} key={blok._uid}/></SbEditable>)
  }
 
  return  (
    <p>{`Component [${blok.component}] does not exist.`}</p>
  )
}

export function RenderComponents(list:Array<any>):Array<JSX.Element> {
  return list.map((blok:any)=>{
    return (<DynamicComponent key={blok._uid} blok={blok} />)
  })
}

export default DynamicComponent