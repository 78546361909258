import React, { createRef, useEffect, useState } from "react"

interface ContactSectionProps {
  isLoading:boolean,

  firstNameRef:React.RefObject<HTMLInputElement>,
  lastNameRef:React.RefObject<HTMLInputElement>,
  affiliationRef:React.RefObject<HTMLInputElement>,
  address1Ref:React.RefObject<HTMLInputElement>,
  address2Ref:React.RefObject<HTMLInputElement>,
  cityRef:React.RefObject<HTMLInputElement>,
  stateRef:React.RefObject<HTMLInputElement>,
  zipRef:React.RefObject<HTMLInputElement>,
  countryRef:React.RefObject<HTMLInputElement>,
  telephoneRef:React.RefObject<HTMLInputElement>,
  emailRef:React.RefObject<HTMLInputElement>
}
function ContactSection({ isLoading, firstNameRef, lastNameRef, affiliationRef, address1Ref, address2Ref, cityRef, stateRef, zipRef, countryRef, telephoneRef, emailRef }:ContactSectionProps):JSX.Element {


  return (
    <div>
      {/* *********** Contact Information *********** */}
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="first_name" required placeholder="First Name*" ref={firstNameRef} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="last_name" required placeholder="Last Name*" ref={lastNameRef} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="affiliation" required placeholder="Affiliation*" ref={affiliationRef} />
      </div>
      {/* Address */}
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="address_1" required placeholder="Address 1*" ref={address1Ref} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="address_2" placeholder="Address 2" ref={address2Ref} />
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group text_box">
            <input disabled={isLoading} type="text" id="city" required placeholder="City*" ref={cityRef} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group text_box">
            <input disabled={isLoading} type="text" id="state" required placeholder="State*" ref={stateRef} />
          </div>
        </div>
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="zip" placeholder="Zip" ref={zipRef} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="country" required placeholder="Country*" ref={countryRef} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="tel" id="telephone" required placeholder="Telephone*" ref={telephoneRef} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="email" id="email" required placeholder="Email*" ref={emailRef} />
      </div>
    </div>
  )
}

///
/// The validate function will return true if it is valid, otherwise a message as to why it failed
///
function ValidateContactSectionValues(data:any):boolean|string {
  if(!data.firstName) {
    return "Please provide a valid first name"
  }
  if(!data.lastName) {
    return "Please provide a valid last name"
  }
  if(!data.affiliation) {
    return "Please provide a valid affiliation"
  }
  if(!data.address1) {
    return "Please provide a valid address"
  }
  if(!data.city) {
    return "Please provide a valid city"
  }
  if(!data.state) {
    return "Please provide a valid state"
  }
  if(!data.country) {
    return "Please provide a valid country"
  }
  if(!data.telephone) {
    return "Please provide a valid telephone number"
  }
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  if(!data.email || !emailPattern.test(data.email || "")) {
    return "Please provide a valid email"
  }

  return true
}

export { ValidateContactSectionValues }
export default ContactSection