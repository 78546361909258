import React from 'react';

import StoryblokImage from '../storyblokImage/StoryblokImage'
import StoryblokLink from '../storyblokLink/StoryblokLink';
import { isLinkDefined } from "../../../utils/utils";
// @ts-ignore
import * as styles from "./EventTeamItem.module.css"

interface EventTeamItemProps {
  image: StoryblokRef.Image,
  name: string,
  post: string,
  link_to: StoryblokRef.Link
}

var breakpoints = {
  "-991":"202x0",
  "-1200":"282x0",
  "1201-":"362x0"
}

function EventTeamItem({image, name, post, link_to}:EventTeamItemProps):JSX.Element {
  return (
    <div className={`item ${styles.team_item}`}>
      <div className={`e_team_img ${styles.team_item_image}`}>
        <StoryblokImage image={image} sizes={breakpoints}/>
      </div>
      {isLinkDefined(link_to) ? <StoryblokLink link={link_to}><h4 className={styles.team_item_link}>{name}</h4></StoryblokLink> : <h4 className={styles.team_item_link}>{name}</h4>}
      <p>{post}</p>
    </div>
  )
}

export default EventTeamItem