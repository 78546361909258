import React from "react"
import { Richtext } from "storyblok-js-client"

import DynamicCTA from "../../cta/DynamicCta"
import { resolveRichText } from "../../../utils/richText"
import StoryblokImage from "../storyblokImage/StoryblokImage"
// @ts-ignore
import * as styles from "./PriceItem.module.css"

interface PriceItemPanel extends Panel {
  content: Richtext,
  image: StoryblokRef.Image,
  buttons: Array<CTA>
}
interface PriceItemProps {
  blok: PriceItemPanel,
  children?: any
}
function PriceItem({ blok }:PriceItemProps):JSX.Element {
  return (
    <div className="col-lg-4 col-sm-6">
      <div className={`s_pricing-item ${styles.item}`}>
        {blok.image && blok.image.filename ? (
          <div className={styles.item_image}>
            <StoryblokImage image={blok.image} />
          </div>
        ) : null}
        <div dangerouslySetInnerHTML={resolveRichText(blok.content)} />
        <div className={styles.btn_wrapper}>
          {blok.buttons ? blok.buttons.map((btn)=>{
            return <DynamicCTA key={btn._uid} blok={btn} />
          }) : null}
        </div>
      </div>
    </div>
  )
}

export { PriceItemPanel }
export default PriceItem