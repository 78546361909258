import React, { useEffect, useState } from "react"

// @ts-ignore
import * as styles from "./OnlineRegistrationCompleteSummaryPanel.module.css"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { FormatCurrency } from "../../../utils/utils"

interface OnlineRegistrationCompleteSummaryPanel extends Panel {

}
interface OnlineRegistrationCompleteSummaryProps {
  blok: OnlineRegistrationCompleteSummaryPanel
}
function OnlineRegistrationCompleteSummaryPanel({ blok }:OnlineRegistrationCompleteSummaryProps) {
  const [isLoading,setIsLoading] = useState<boolean>(true)
  const [registrationDetails,setRegistrationDetails] = useState<OnlineRegistrationRecord>()

  function fetchRegistrationData() {
    let urlParams = new URLSearchParams(window.location.search)
    const customer_reference_id = urlParams.get("c")
    
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/forms?c=${customer_reference_id}`, {
      method: 'GET'
    })
      .then((response)=>{
        response.text().then((responseString)=>{
          const d = JSON.parse(responseString)
          const regData:OnlineRegistrationRecord = d.data.data
          
          setRegistrationDetails(regData)
          setIsLoading(false)
        }).catch((err)=>{
          console.error(err)
        })
      })
      .catch((err)=>{
        console.error(err)
      })
  }

  useEffect(()=>{
    fetchRegistrationData()
  },[])
  
  
  let items:Array<string> = []
  if(registrationDetails) {
    registrationDetails.purchase_items?.map((item)=>{
      if(item) {
        items.push(`  ${item.label} - (${item.quantity} @ ${FormatCurrency(item.price)})`)
      }
    })
  }

  return (
    <PanelWrapper blok={blok}>
      {isLoading ? (
        <div className={styles.loading}>
          Please wait while your order summary is loaded.
        </div>
      ) : (
        <div className="container">
          <h3>Your contact information</h3>
          <div>Name: {`${registrationDetails?.firstName} ${registrationDetails?.lastName}`}</div>
          <div>Affiliation: {registrationDetails?.affiliation}</div>
          <div>Address 1: {`${registrationDetails?.address1} ${registrationDetails?.address2}`}</div>
          <div>Address 2: {`${registrationDetails?.city}, ${registrationDetails?.state}, ${registrationDetails?.zip}, ${registrationDetails?.country}`}</div>
          <div>Telephone: {registrationDetails?.telephone}</div>
          <div>Email: {registrationDetails?.email}</div>
          <br/>
          <div>
            <div>---------------------</div>
            {items.map(((item,idx)=>(
              <div key={idx}>{item}</div>
            )))}
            <div>{`Total Charged: ${FormatCurrency(registrationDetails?.amount_charged || 0)}`}</div>
            <div>---------------------</div>
          </div>
        </div>
      )}
    </PanelWrapper>
  )
}

export default OnlineRegistrationCompleteSummaryPanel