import React, { createRef, useEffect, useState } from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import AsideWrapper, { AsideLeft, AsideRight } from "../../internal/asideWrapper/AsideWrapper"
import { FormatCurrency } from "../../../utils/utils"
// @ts-ignore
import * as formStyles from "../formStyles.module.css"
import useRegistrationTypes, { RegistrationType } from "../../../hooks/useRegistrationTypes"
import useStripeItems from "../../../hooks/useStripeItems"
import ContactSection, { ValidateContactSectionValues } from "../../formSections/contactSection/ContactSection"
import WelcomeReceptionSection, { ValidateWelcomeReceptionSectionValues } from "../../formSections/welcomeReceptionSection/WelcomeReceptionSection"
import BanquetDinnerSection, { ValidateBanquetDinnerSectionValues } from "../../formSections/banquetDinnerSection/BanquetDinnerSection"

interface OnsiteRegistrationPanel extends Panel {
  headline: Richtext,
  registration_types: Array<string>,
  banquet_adult_stripe_item: string,
  banquet_child_stripe_item: string,

  success_redirect_to: StoryblokRef.Link,
  cancel_redirect_to: StoryblokRef.Link
}
interface OnsiteRegistrationFormProps {
  blok: OnsiteRegistrationPanel
}
function OnsiteRegistrationForm({ blok }:OnsiteRegistrationFormProps):JSX.Element {
  const registrationTypes = useRegistrationTypes()
  const stripeItems = useStripeItems()
  const adultStripeItem = stripeItems.find((item)=>{return item.uuid===blok.banquet_adult_stripe_item})
  const childStripeItem = stripeItems.find((item)=>{return item.uuid===blok.banquet_child_stripe_item})


  const [isLoading, setIsLoading] = useState<boolean>(false),
        [errorMessage, setErrorMessage] = useState<string>(""),
        [registrationType, setRegistrationType] = useState<string>(""),
        firstNameRef = createRef<HTMLInputElement>(),
        lastNameRef = createRef<HTMLInputElement>(),
        affiliationRef = createRef<HTMLInputElement>(),
        address1Ref = createRef<HTMLInputElement>(),
        address2Ref = createRef<HTMLInputElement>(),
        cityRef = createRef<HTMLInputElement>(),
        stateRef = createRef<HTMLInputElement>(),
        zipRef = createRef<HTMLInputElement>(),
        countryRef = createRef<HTMLInputElement>(),
        telephoneRef = createRef<HTMLInputElement>(),
        emailRef = createRef<HTMLInputElement>(),
        [registrationOptions, setRegistrationOptions] = useState<Array<RegistrationType>>([]),

        [willAttendWelcomeReception, setWillAttendWelcomeReception] = useState<boolean>(true),
        [welcomeReceptionCount, setWelcomeReceptionCount] = useState<number>(0),

        [willAttendBanquet, setWillAttendBanquet] = useState<boolean>(true),
        [banquetAdultMealsCount, setBanquetAdultMealsCount] = useState<number>(0),
        [banquetChildMealsCount, setBanquetChildMealsCount] = useState<number>(0)

  useEffect(()=>{
    if(blok.registration_types) {
      let options = registrationTypes.filter((type)=>{
        return blok.registration_types.indexOf(type.uuid) != -1
      }).sort((a,b)=>{
        if (a.preferred_dropdown_order === b.preferred_dropdown_order) {
          return 0
        }
        return (a.preferred_dropdown_order || 0) > (b.preferred_dropdown_order || 0) ? 1 : -1
      })
      setRegistrationOptions(options)

      // Default Registration Value
      let nameToFind = window.location.hash.substring(1)
      if(nameToFind) {
        nameToFind = decodeURIComponent(nameToFind)
      }
      let defaultOption = options.find((type)=>{ return nameToFind === type.name })
      if(defaultOption) {
        setRegistrationType(defaultOption.uuid)
      }
    }

    if(window && window.location && window.location.hash) {
      let timeout = setTimeout(()=>{
        window.scrollTo(0,0)
      }, 10)
    }
  }, [])

  let selectedRegistrationType = registrationOptions.find((type)=>{return type.uuid === registrationType})
  let runningTotal:number = 0
      
  if(banquetAdultMealsCount > 0) {
    runningTotal += (banquetAdultMealsCount * (adultStripeItem?.amount || 0))
  }
  if(banquetChildMealsCount > 0) {
    runningTotal += (banquetChildMealsCount * (childStripeItem?.amount || 0))
  }
  if(selectedRegistrationType) {
    runningTotal += selectedRegistrationType.amount
  }


  function handleRegistrationTypeChanged(event:React.ChangeEvent<HTMLSelectElement>) {
    setRegistrationType(event.target.value)
  }
  function ValidateForm(data:OnsiteRegistrationRecord):string|boolean {
    
    let contactValidation = ValidateContactSectionValues(data)
    if(typeof contactValidation === "string") {
      return contactValidation
    }

    let banquetValidation = ValidateBanquetDinnerSectionValues(data)
    if(typeof banquetValidation === "string") {
      return banquetValidation
    }

    let receptionValidation = ValidateWelcomeReceptionSectionValues(data)
    if(typeof receptionValidation === "string") {
      return receptionValidation
    }

    return true
  }
  function handleSubmit(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    let items:Array<PurchaseItem> = []

    if(selectedRegistrationType) {
      items.push({
        key: selectedRegistrationType.uuid,
        label: selectedRegistrationType.line_item_label || selectedRegistrationType.name || "",
        quantity: 1,
        price: selectedRegistrationType.amount,
        stripe_price_id: selectedRegistrationType.stripe_price_id
      })
    }

    if(banquetAdultMealsCount) {
      items.push({
        key: adultStripeItem?.uuid || "",
        label: adultStripeItem?.line_item_label || adultStripeItem?.name || "",
        quantity: banquetAdultMealsCount,
        price: adultStripeItem?.amount || 0,
        stripe_price_id: adultStripeItem?.stripe_price_id || ""
      })
    }
    if(banquetChildMealsCount) {
      items.push({
        key: childStripeItem?.uuid || "",
        label: childStripeItem?.line_item_label || childStripeItem?.name || "",
        quantity: banquetChildMealsCount,
        price: childStripeItem?.amount || 0,
        stripe_price_id: childStripeItem?.stripe_price_id || ""
      })
    }

    const registrationRecord:OnsiteRegistrationRecord = {
      form_type: 'onsite',
      firstName: `${firstNameRef.current?.value}`.trim(),
      lastName: `${lastNameRef.current?.value}`.trim(),
      affiliation: `${affiliationRef.current?.value}`.trim(),
      address1: `${address1Ref.current?.value}`.trim(),
      address2: `${address2Ref.current?.value}`.trim(),
      city: `${cityRef.current?.value}`.trim(),
      state: `${stateRef.current?.value}`.trim(),
      zip: `${zipRef.current?.value}`.trim(),
      country: `${countryRef.current?.value}`.trim(),
      telephone: `${telephoneRef.current?.value}`.trim(),
      email: `${emailRef.current?.value}`.trim(),
    
      registrationType: selectedRegistrationType?.name || "",
      purchase_items: items,

      willAttendWelcomeReception,
      additionalWelcomeReceptionGuestsCount: welcomeReceptionCount,

      willAttendBanquet,
      additionalBanquetAdultMeals: banquetAdultMealsCount,
      additionalBanquetChildMeals: banquetChildMealsCount
    }
    
    const validateResults = ValidateForm(registrationRecord)
    if(validateResults === true) {
      const data = {
        form: 'ONSITE_REGISTRATION_FORM',
        registration: registrationRecord,
        success_url: blok.success_redirect_to ? blok.success_redirect_to.cached_url : '',
        cancel_url: blok.cancel_redirect_to ? blok.cancel_redirect_to.cached_url : '',
        return_domain: window.location.origin
      }

      setIsLoading(true)
      setErrorMessage("")
      
      fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/forms`, {
        method: "POST",
        body: JSON.stringify(data)
      })
      .then((response)=>{
        response.text()
        .then((results)=>{
          const responseData = JSON.parse(results)
          window.location.href = responseData.data.checkoutURL
        })
        .catch((err)=>{
          setIsLoading(false)
          setErrorMessage("Failed to setup payment in stripe")
        })
      })
      .catch((err)=>{
        setIsLoading(false)
        setErrorMessage("Failed to setup payment in stripe")
      })
    } else {
      setErrorMessage(`${validateResults}`)
      let ele = document.getElementById('error_message')
      if(ele) {
        ele.scrollIntoView()
      }
    }
  }

  return (
    <PanelWrapper blok={blok}>
      <AsideWrapper>
        <AsideRight>
          <div className="sticky-lg-top registration-sticky">
            <h5>Estimated Totals</h5>
            <hr/>
            {selectedRegistrationType ? (
              <div>
                {selectedRegistrationType.line_item_label || selectedRegistrationType.name}
                <span style={{float: 'right'}}>{`1 @ ${FormatCurrency(selectedRegistrationType.amount)}`}</span>
                <div style={{clear: 'both'}} />
              </div>
            ) : null}
            {banquetAdultMealsCount > 0 ? (
              <div>
                {adultStripeItem?.line_item_label || adultStripeItem?.name}
                <span style={{float: 'right'}}>{`${banquetAdultMealsCount} @ ${FormatCurrency(adultStripeItem?.amount || 0)}`}</span>
                <div style={{clear: 'both'}} />
              </div>
            ) : null}
            {banquetChildMealsCount > 0 ? (
              <div>
                {childStripeItem?.line_item_label || childStripeItem?.name}
                <span style={{float: 'right'}}>{`${banquetChildMealsCount} @ ${FormatCurrency(childStripeItem?.amount || 0)}`}</span>
                <div style={{clear: 'both'}} />
              </div>
            ) : null}
            <hr/>
            <div>
              <div style={{float: 'right'}}>Total: {FormatCurrency(runningTotal)}</div>
              <div style={{clear: 'both'}} />
            </div>
          </div>
        </AsideRight>
        <AsideLeft>
          <form id="error_message" onSubmit={handleSubmit}>
            <div className="form-group">
              <select disabled={isLoading} className="registration-select" required value={registrationType} onChange={handleRegistrationTypeChanged} placeholder="Registration Type">
                <option value="">Select A Registration Type</option>
                {registrationOptions.map((type)=>(
                  <option key={type.uuid} value={type.uuid}>{type.name} ({FormatCurrency(type.amount)})</option>
                ))}
              </select>
            </div>
            <br/>
            <h3>Your contact information</h3>
            <div style={errorMessage ? {display: 'block'} : {display: 'none'}}><p className={formStyles.errorMessage}>{errorMessage}</p></div>
            <ContactSection 
                isLoading={isLoading}
                firstNameRef={firstNameRef}
                lastNameRef={lastNameRef}
                affiliationRef={affiliationRef}
                address1Ref={address1Ref}
                address2Ref={address2Ref}
                cityRef={cityRef}
                stateRef={stateRef}
                zipRef={zipRef}
                countryRef={countryRef}
                telephoneRef={telephoneRef}
                emailRef={emailRef} />

            <h3>Event Information</h3>
            <WelcomeReceptionSection
              isLoading={isLoading}
              welcomeReceptionCount={welcomeReceptionCount}
              setWelcomeReceptionCount={setWelcomeReceptionCount}
              willAttendWelcomeReception={willAttendWelcomeReception}
              setWillAttendWelcomeReception={setWillAttendWelcomeReception} />

            <BanquetDinnerSection
              isLoading={isLoading}
              willAttendBanquet={willAttendBanquet}
              setWillAttendBanquet={setWillAttendBanquet}
              banquetAdultMealsCount={banquetAdultMealsCount}
              setBanquetAdultMealsCount={setBanquetAdultMealsCount}
              banquetChildMealsCount={banquetChildMealsCount}
              setBanquetChildMealsCount={setBanquetChildMealsCount}
              adultMealCost={adultStripeItem?.amount || 0}
              childMealCost={childStripeItem?.amount || 0} />

            <button className="parc-btn solid orange" disabled={isLoading} type="submit">Next</button>
          </form>
        </AsideLeft>
      </AsideWrapper>
    </PanelWrapper>
  )
}

export default OnsiteRegistrationForm