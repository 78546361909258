import React from "react"
import { Richtext } from "storyblok-js-client"

import EventFeaturesItem, { EventFeatureItem } from "../../internal/eventFeaturesItem/EventFeaturesItem"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../utils/richText"

interface EventFeaturesPanel extends Panel {
  headline: Richtext,
  items: Array<EventFeatureItem>
}
interface EventFeaturesPanelProps {
  blok: EventFeaturesPanel
}
function EventFeatures({ blok }:EventFeaturesPanelProps) {
  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="container">
          <div className="hosting_title security_title text-center">
            {blok.headline ? <div className="wow fadeInUp" data-wow-delay="0.2s" dangerouslySetInnerHTML={resolveRichText(blok.headline)} /> : null}
          </div>
          <div className="row event_features_inner">
            {blok.items ? blok.items.map((feature)=>(
              <EventFeaturesItem 
                key={feature._uid}
                cta_link={feature.cta_link} 
                image={feature.image}
                title={feature.title}
                content={feature.content}
                cta_label={feature.cta_label} />
            )) : null}
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

export default EventFeatures