import React, { createRef, useEffect, useState } from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../internal/panelWrapper/PanelWrapper"
import AsideWrapper, { AsideLeft, AsideRight } from "../internal/asideWrapper/AsideWrapper"
import { FormatCurrency } from "../../utils/utils"
// @ts-ignore
import * as formStyles from "./formStyles.module.css"
import useRegistrationTypes, { RegistrationType } from "../../hooks/useRegistrationTypes"
import useStripeItems from "../../hooks/useStripeItems"

interface RegistrationPanel extends Panel {
  form_type: string,
  headline: Richtext,
  registration_types: Array<string>,
  use_welcome_reception_section: boolean,
  welcome_reception_stripe_item: string,
  use_banquet_section: boolean,
  banquet_adult_stripe_item: string,
  banquet_child_stripe_item: string,

  success_redirect_to: StoryblokRef.Link,
  cancel_redirect_to: StoryblokRef.Link
}
interface RegistrationFormProps {
  blok: RegistrationPanel
}

function RegistrationForm({ blok }:RegistrationFormProps):JSX.Element {
  const registrationTypes = useRegistrationTypes()
  const stripeItems = useStripeItems()
  const adultStripeItem = stripeItems.find((item)=>{return item.uuid===blok.banquet_adult_stripe_item})
  const childStripeItem = stripeItems.find((item)=>{return item.uuid===blok.banquet_child_stripe_item})
  
  const [isLoading, setIsLoading] = useState<boolean>(false),
        [errorMessage, setErrorMessage] = useState<string>(""),
        [registrationType, setRegistrationType] = useState<string>(""),
        firstNameRef = createRef<HTMLInputElement>(),
        lastNameRef = createRef<HTMLInputElement>(),
        affiliationRef = createRef<HTMLInputElement>(),
        address1Ref = createRef<HTMLInputElement>(),
        address2Ref = createRef<HTMLInputElement>(),
        cityRef = createRef<HTMLInputElement>(),
        stateRef = createRef<HTMLInputElement>(),
        zipRef = createRef<HTMLInputElement>(),
        countryRef = createRef<HTMLInputElement>(),
        telephoneRef = createRef<HTMLInputElement>(),
        emailRef = createRef<HTMLInputElement>(),

        [willAttendWelcomeReception, setWillAttendWelcomeReception] = useState<boolean>(true),
        [welcomeReceptionCount, setWelcomeReceptionCount] = useState<number>(0),

        [willAttendBanquet, setWillAttendBanquet] = useState<boolean>(true),
        [banquetAdultMealsCount, setBanquetAdultMealsCount] = useState<number>(0),
        [banquetChildMealsCount, setBanquetChildMealsCount] = useState<number>(0),
        [registrationOptions, setRegistrationOptions] = useState<Array<RegistrationType>>([])
  
  useEffect(()=>{
    if(blok.registration_types) {
      let options = registrationTypes.filter((type)=>{
        return blok.registration_types.indexOf(type.uuid) != -1
      }).sort((a,b)=>{
        if (a.preferred_dropdown_order === b.preferred_dropdown_order) {
          return 0
        }
        return (a.preferred_dropdown_order || 0) > (b.preferred_dropdown_order || 0) ? 1 : -1
      })
      setRegistrationOptions(options)

      // Default Registration Value
      let nameToFind = window.location.hash.substring(1)
      let defaultOption = options.find((type)=>{ return nameToFind === type.name })
      if(defaultOption) {
        setRegistrationType(defaultOption.uuid)
      }
    }
  }, [])

  let selectedRegistrationType = registrationOptions.find((type)=>{return type.uuid === registrationType})
  let runningTotal:number = 0

  if(blok.use_banquet_section) {
    if(banquetAdultMealsCount > 0) {
      runningTotal += (banquetAdultMealsCount * (adultStripeItem?.amount || 0))
    }
    if(banquetChildMealsCount > 0) {
      runningTotal += (banquetChildMealsCount * (childStripeItem?.amount || 0))
    }
  }


  if(selectedRegistrationType) {
    runningTotal += selectedRegistrationType.amount
  }

  function handleCostNumberChanged(prop: string, event:React.ChangeEvent<HTMLInputElement>) {
    // Handle update the props
    switch(prop) {
      case 'welcomeReceptionCount':
        setWelcomeReceptionCount(parseInt(event.target.value) || 0)
        break
      case 'banquetAdultMealsCount':
        setBanquetAdultMealsCount(parseInt(event.target.value) || 0)
        break
      case 'banquetChildMealsCount':
        setBanquetChildMealsCount(parseInt(event.target.value) || 0)
        break
      default:
        console.warn(`Cost Property not handled [${prop}]`)
    }
  }
  function handleRegistrationTypeChanged(event:React.ChangeEvent<HTMLSelectElement>) {
    setRegistrationType(event.target.value)
  }
  function handleRadioToggleChanged(prop:string, event:React.ChangeEvent<HTMLInputElement>) {
    switch(prop) {
      case 'willAttendWelcomeReception':
        setWillAttendWelcomeReception(event.target.value === 'Yes' ? true : false)
        if(event.target.value === 'No') {
          setWelcomeReceptionCount(0)
        }
        break
      case 'willAttendBanquet':
        setWillAttendBanquet(event.target.value === 'Yes' ? true : false)
        if(event.target.value === 'No') {
          setBanquetAdultMealsCount(0)
          setBanquetChildMealsCount(0)
        }
        break
      default:
        console.warn(`Radio Property not handled [${prop}]`)
    }
  }

  function ValidateForm(data:RegistrationRecord):string|boolean {
    if(!data.firstName) {
      return "Please provide a valid first name"
    }
    if(!data.lastName) {
      return "Please provide a valid last name"
    }
    if(!data.affiliation) {
      return "Please provide a valid affiliation"
    }
    if(!data.address1) {
      return "Please provide a valid address"
    }
    if(!data.city) {
      return "Please provide a valid city"
    }
    if(!data.state) {
      return "Please provide a valid state"
    }
    if(!data.country) {
      return "Please provide a valid country"
    }
    if(!data.telephone) {
      return "Please provide a valid telephone number"
    }
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if(!data.email || !emailPattern.test(data.email || "")) {
      return "Please provide a valid email"
    }

    return true
  }

  function handleSubmitPressed(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    let items:Array<string> = []
    if(banquetAdultMealsCount) {
      items.push(`${adultStripeItem?.line_item_label || adultStripeItem?.name || ""} ${banquetAdultMealsCount} @ ${FormatCurrency(adultStripeItem?.amount || 0)}/meal`)
    }
    if(banquetChildMealsCount) {
      items.push(`${childStripeItem?.line_item_label || childStripeItem?.name || ""} ${banquetChildMealsCount} @ ${FormatCurrency(childStripeItem?.amount || 0)}/meal`)
    }
    const registrationData:RegistrationRecord = {
      form_type: blok.form_type,
      registrationTypeID: registrationType,
      registrationType: selectedRegistrationType ? selectedRegistrationType.name : "",
      firstName: `${firstNameRef.current?.value}`.trim(),
      lastName: `${lastNameRef.current?.value}`.trim(),
      affiliation: `${affiliationRef.current?.value}`.trim(),
      address1: `${address1Ref.current?.value}`.trim(),
      address2: `${address2Ref.current?.value}`.trim(),
      city: `${cityRef.current?.value}`.trim(),
      state: `${stateRef.current?.value}`.trim(),
      zip: `${zipRef.current?.value}`.trim(),
      country: `${countryRef.current?.value}`.trim(),
      telephone: `${telephoneRef.current?.value}`.trim(),
      email: `${emailRef.current?.value}`.trim(),

      willAttendWelcomeReception,
      additionalWelcomeReceptionGuestsCount: welcomeReceptionCount,

      willAttendBanquet,
      additionalBanquetAdultStripePriceID: adultStripeItem?.stripe_price_id || "",
      additionalBanquetAdultMeals: banquetAdultMealsCount,
      additionalBanquetChildStripePriceID: childStripeItem?.stripe_price_id || "",
      additionalBanquetChildMeals: banquetChildMealsCount,

      registration_cost: FormatCurrency(selectedRegistrationType ? selectedRegistrationType.amount : 0),
      line_items: items,
      estimated_cost: FormatCurrency(runningTotal)
    }

    const validateResults = ValidateForm(registrationData)
    if (validateResults === true) {
      const data = {
        form: 'REGISTRATION_FORM',
        registration: registrationData,
        success_url: blok.success_redirect_to ? blok.success_redirect_to.cached_url : '',
        cancel_url: blok.cancel_redirect_to ? blok.cancel_redirect_to.cached_url : '',
        return_domain: window.location.origin
      }
      
      setIsLoading(true)
      setErrorMessage("")
      fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/forms`, {
        method: "POST",
        body: JSON.stringify(data)
      })
      .then((response)=>{
        response.text()
        .then((results)=>{
          const responseData = JSON.parse(results)
          window.location.href = responseData.data.checkoutURL
        })
        .catch((err)=>{
          setIsLoading(false)
          setErrorMessage("Failed to setup payment in stripe")
        })
      })
      .catch((err)=>{
        setIsLoading(false)
        setErrorMessage("Failed to setup payment in stripe")
      })
    } else {
      setErrorMessage(`${validateResults}`)
      let ele = document.getElementById('error_message')
      if(ele) {
        ele.scrollIntoView()
      }
    }
  }


  return (
    <PanelWrapper blok={blok}>
      <AsideWrapper>
        <AsideRight>
          <div className="">
            <div className="sticky-lg-top registration-sticky">
              <h5>Estimated Totals</h5>
              <hr/>
              {selectedRegistrationType ? (
                <div>
                  {selectedRegistrationType.line_item_label || selectedRegistrationType.name}
                  <span style={{float: 'right'}}>{`1 @ ${FormatCurrency(selectedRegistrationType.amount)}`}</span>
                  <div style={{clear: 'both'}} />
                </div>
              ) : null}
              {banquetAdultMealsCount > 0 ? (
                <div>
                  {adultStripeItem?.line_item_label || adultStripeItem?.name}
                  <span style={{float: 'right'}}>{`${banquetAdultMealsCount} @ ${FormatCurrency(adultStripeItem?.amount || 0)}`}</span>
                  <div style={{clear: 'both'}} />
                </div>
              ) : null}
              {banquetChildMealsCount > 0 ? (
                <div>
                  {childStripeItem?.line_item_label || childStripeItem?.name}
                  <span style={{float: 'right'}}>{`${banquetChildMealsCount} @ ${FormatCurrency(childStripeItem?.amount || 0)}`}</span>
                  <div style={{clear: 'both'}} />
                </div>
              ) : null}
              <hr/>
              <div>
                <div style={{float: 'right'}}>Total: {FormatCurrency(runningTotal)}</div>
                <div style={{clear: 'both'}} />
              </div>
            </div>
          </div>
        </AsideRight>
        <AsideLeft>
          <form id="error_message" onSubmit={handleSubmitPressed}>
            <div className="form-group">
              <select disabled={isLoading} className="registration-select" required value={registrationType} onChange={handleRegistrationTypeChanged} placeholder="Registration Type">
                <option value="">Select A Registration Type</option>
                {registrationOptions.map((type)=>(
                  <option key={type.uuid} value={type.uuid}>{type.name} ({FormatCurrency(type.amount)})</option>
                ))}
              </select>
            </div>
            <br/>
            {/* *********** Contact Information *********** */}
            <h3>Your contact information</h3>
            <div style={errorMessage ? {display: 'block'} : {display: 'none'}}><p className={formStyles.errorMessage}>{errorMessage}</p></div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="first_name" required placeholder="First Name*" ref={firstNameRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="last_name" required placeholder="Last Name*" ref={lastNameRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="affiliation" required placeholder="Affiliation*" ref={affiliationRef} />
            </div>
            {/* Address */}
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="address_1" required placeholder="Address 1*" ref={address1Ref} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="address_2" placeholder="Address 2" ref={address2Ref} />
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group text_box">
                  <input disabled={isLoading} type="text" id="city" required placeholder="City*" ref={cityRef} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group text_box">
                  <input disabled={isLoading} type="text" id="state" required placeholder="State*" ref={stateRef} />
                </div>
              </div>
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="zip" placeholder="Zip" ref={zipRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="country" required placeholder="Country*" ref={countryRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="tel" id="telephone" required placeholder="Telephone*" ref={telephoneRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="email" id="email" required placeholder="Email*" ref={emailRef} />
            </div>
            {/* *********** Event information *********** */}
            {blok.use_welcome_reception_section ? (
              <div>
                <h3>Event Information</h3>
                {/* Welcome Reception */}
                <div>
                  <div className="row">
                    <div className="col-lg-12">
                      Will you be attending the <b>Welcome Reception</b> on Monday?
                    </div>
                    <div className="col-lg-3">
                      <input disabled={isLoading} type="radio" value="Yes" checked={willAttendWelcomeReception === true} onChange={(e)=>{handleRadioToggleChanged('willAttendWelcomeReception', e)}} />Yes
                    </div>
                    <div className="col-lg-3">
                      <input disabled={isLoading} type="radio" value="No" checked={willAttendWelcomeReception === false} onChange={(e)=>{handleRadioToggleChanged('willAttendWelcomeReception', e)}} />No
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      If yes, how many non-conference attendee guests will be attending with you?
                    </div>
                    <div className="col-lg-6">
                      <div className="text_box">
                        <input disabled={isLoading || willAttendWelcomeReception === false} type="number" required value={welcomeReceptionCount} onChange={(e)=>{handleCostNumberChanged('welcomeReceptionCount', e)}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {/* Banquet */}
            {blok.use_banquet_section ? (
              <div>
                <div className="row">
                  <div className="col-lg-12">Will you be attending the <b>Banquet</b> on Wednesday?</div>
                  <div className="col-lg-3">
                    <input disabled={isLoading} type="radio" value="Yes" checked={willAttendBanquet === true} onChange={(e)=>{handleRadioToggleChanged('willAttendBanquet', e)}} />Yes
                  </div>
                  <div className="col-lg-3">
                    <input disabled={isLoading} type="radio" value="No" checked={willAttendBanquet === false} onChange={(e)=>{handleRadioToggleChanged('willAttendBanquet', e)}} />No
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    If you will have additional non-conference attendee guests joining you at the Banquet, you can purchase additional banquet meals below.
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <label htmlFor="banquet_adult_meals">Adult Meals ({FormatCurrency(adultStripeItem?.amount || 0)}/meal)</label>
                      <input disabled={isLoading || willAttendBanquet === false} type="number" id="banquet_adult_meals" placeholder="Adult Meals" value={banquetAdultMealsCount} onChange={(e)=>{handleCostNumberChanged('banquetAdultMealsCount', e)}} />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <label htmlFor="banquet_child_meals">Child Meals ({FormatCurrency(childStripeItem?.amount || 0)}/meal)</label>
                      <input disabled={isLoading || willAttendBanquet === false} type="number" id="banquet_child_meals" placeholder="Child Meals" value={banquetChildMealsCount} onChange={(e)=>{handleCostNumberChanged('banquetChildMealsCount', e)}} />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <button className="parc-btn solid orange" disabled={isLoading} type="submit">Next</button>
          </form>
        </AsideLeft>
      </AsideWrapper>
    </PanelWrapper>
  )
}

export default RegistrationForm