import React, { useState, createRef } from "react"
import AsideWrapper, { AsideLeft, AsideRight} from "../../internal/asideWrapper/AsideWrapper"

import useStripeItems from "../../../hooks/useStripeItems"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import ContactMiniSection, { ValidateContactMiniSectionValues } from "../../formSections/contactMiniSection/ContactMiniSection"
// @ts-ignore
import * as formStyles from "../formStyles.module.css"
import { FormatCurrency } from "../../../utils/utils"



interface AlACarteFormPanel extends Panel {
  success_redirect_to: StoryblokRef.Link,
  cancel_redirect_to: StoryblokRef.Link,

  banquet_adult_stripe_item:string,
  banquet_child_stripe_item:string,
}
interface AlACarteFormProps {
  blok: AlACarteFormPanel
}
function AlACarteForm({ blok }:AlACarteFormProps) {
  const stripeItems = useStripeItems()

  const adultStripeItem = stripeItems.find((item)=>{return item.uuid===blok.banquet_adult_stripe_item})
  const childStripeItem = stripeItems.find((item)=>{return item.uuid===blok.banquet_child_stripe_item})

  const [isLoading, setIsLoading] = useState<boolean>(false),
        [errorMessage,setErrorMessage] = useState<string>(""),

        firstNameRef = createRef<HTMLInputElement>(),
        lastNameRef = createRef<HTMLInputElement>(),
        affiliationRef = createRef<HTMLInputElement>(),
        telephoneRef = createRef<HTMLInputElement>(),
        emailRef = createRef<HTMLInputElement>(),

        [banquetAdultMealsCount, setBanquetAdultMealsCount] = useState<number>(0),
        [banquetChildMealsCount, setBanquetChildMealsCount] = useState<number>(0),

        attendeeNameRef = createRef<HTMLInputElement>()

  let runningTotal:number = 0

  if(banquetAdultMealsCount > 0) {
    runningTotal += (banquetAdultMealsCount * (adultStripeItem?.amount || 0))
  }
  if(banquetChildMealsCount > 0) {
    runningTotal += (banquetChildMealsCount * (childStripeItem?.amount || 0))
  }


  function handleCostNumberChanged(prop: string, event:React.ChangeEvent<HTMLInputElement>) {
    switch(prop) {
      case 'banquetAdultMealsCount':
        setBanquetAdultMealsCount(parseInt(event.target.value) || 0)
        break
      case 'banquetChildMealsCount':
        setBanquetChildMealsCount(parseInt(event.target.value) || 0)
        break
      default:
        console.warn(`Cost Property not handled [${prop}]`)
    }
  }

  function ValidateForm(data:AlACarteRegistrationRecord):string|boolean {
    let contactValidation = ValidateContactMiniSectionValues(data)
    if(typeof contactValidation === "string") {
      return contactValidation
    }

    if(banquetAdultMealsCount < 1 && banquetChildMealsCount < 1) {
      return "Please add some meals to purchase"
    }

    return true
  }
  
  function handleSubmit(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    let items:Array<PurchaseItem> = []

    if(banquetAdultMealsCount) {
      items.push({
        key: adultStripeItem?.uuid || "",
        label: adultStripeItem?.line_item_label || adultStripeItem?.name || "",
        quantity: banquetAdultMealsCount,
        price: adultStripeItem?.amount || 0,
        stripe_price_id: adultStripeItem?.stripe_price_id || ""
      })
    }
    if(banquetChildMealsCount) {
      items.push({
        key: childStripeItem?.uuid || "",
        label: childStripeItem?.line_item_label || childStripeItem?.name || "",
        quantity: banquetChildMealsCount,
        price: childStripeItem?.amount || 0,
        stripe_price_id: childStripeItem?.stripe_price_id || ""
      })
    }

    const alacartRecord:AlACarteRegistrationRecord = {
      firstName: `${firstNameRef.current?.value}`.trim(),
      lastName: `${lastNameRef.current?.value}`.trim(),
      affiliation: `${affiliationRef.current?.value}`.trim(),
      telephone: `${telephoneRef.current?.value}`.trim(),
      email: `${emailRef.current?.value}`.trim(),

      attendee_name: `${attendeeNameRef.current?.value}`.trim(),

      purchase_items: items,

      additionalBanquetAdultMeals: banquetAdultMealsCount,
      additionalBanquetChildMeals: banquetChildMealsCount,
    }

    const validateResults = ValidateForm(alacartRecord)
    if(validateResults) {
      const data = {
        form: 'ALACARTE_FORM',
        record: alacartRecord,
        success_url: blok.success_redirect_to ? blok.success_redirect_to.cached_url : '',
        cancel_url: blok.cancel_redirect_to ? blok.cancel_redirect_to.cached_url : '',
        return_domain: window.location.origin
      }
      
      setIsLoading(true)
      setErrorMessage("")
      fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/forms`, {
        method: "POST",
        body: JSON.stringify(data)
      })
      .then((response)=>{
        response.text()
        .then((results)=>{
          const responseData = JSON.parse(results)
          window.location.href = responseData.data.checkoutURL
        })
        .catch((err)=>{
          setIsLoading(false)
          setErrorMessage("Failed to setup payment in stripe")
        })
      })
      .catch((err)=>{
        setIsLoading(false)
        setErrorMessage("Failed to setup payment in stripe")
      })
    } else {
      setErrorMessage(`${validateResults}`)
      let ele = document.getElementById('error_message')
      if(ele) {
        ele.scrollIntoView()
      }
    }
  }

  return (
    <PanelWrapper blok={blok}>
      <AsideWrapper>
        <AsideRight>
          <div className="sticky-lg-top registration-sticky">
            <h5>Estimated Totals</h5>
            <hr/>
            {banquetAdultMealsCount > 0 ? (
              <div>
                {adultStripeItem?.line_item_label || adultStripeItem?.name}
                <span style={{float: 'right'}}>{`${banquetAdultMealsCount} @ ${FormatCurrency(adultStripeItem?.amount || 0)}`}</span>
                <div style={{clear: 'both'}} />
              </div>
            ) : null}
            {banquetChildMealsCount > 0 ? (
              <div>
                {childStripeItem?.line_item_label || childStripeItem?.name}
                <span style={{float: 'right'}}>{`${banquetChildMealsCount} @ ${FormatCurrency(childStripeItem?.amount || 0)}`}</span>
                <div style={{clear: 'both'}} />
              </div>
            ) : null}
            <hr/>
            <div>
              <div style={{float: 'right'}}>Total: {FormatCurrency(runningTotal)}</div>
              <div style={{clear: 'both'}} />
            </div>
          </div>
        </AsideRight>
        <AsideLeft>
          <form id="error_message" onSubmit={handleSubmit}>
            
            <div style={errorMessage ? {display: 'block'} : {display: 'none'}}><p className={formStyles.errorMessage}>{errorMessage}</p></div>
            
            <h3>Your contact information</h3>
            <ContactMiniSection 
              isLoading={isLoading}
              firstNameRef={firstNameRef}
              lastNameRef={lastNameRef}
              affiliationRef={affiliationRef}
              telephoneRef={telephoneRef}
              emailRef={emailRef} />

            <h3>Registered PARC attendee (if known, otherwise leave blank)</h3>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="attendee_name" placeholder="Attendee Name" ref={attendeeNameRef} />
            </div>

            <div className="row">
              <div className="col-lg-12">
                <h3>Extra meals to purchase</h3>
              </div>
              <div className="col-lg-6">
                <div className="form-group text_box">
                  <label htmlFor="banquet_adult_meals">Adult Meals ({FormatCurrency(adultStripeItem?.amount || 0)}/meal)</label>
                  <input disabled={isLoading} type="number" id="banquet_adult_meals" placeholder="Adult Meals" value={banquetAdultMealsCount} onChange={(e)=>{handleCostNumberChanged('banquetAdultMealsCount', e)}} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group text_box">
                  <label htmlFor="banquet_child_meals">Child Meals ({FormatCurrency(childStripeItem?.amount || 0)}/meal)</label>
                  <input disabled={isLoading} type="number" id="banquet_child_meals" placeholder="Child Meals" value={banquetChildMealsCount} onChange={(e)=>{handleCostNumberChanged('banquetChildMealsCount', e)}} />
                </div>
              </div>
            </div>
            <button className="parc-btn solid orange" disabled={isLoading} type="submit">Next</button>
          </form>
        </AsideLeft>
      </AsideWrapper>
    </PanelWrapper>
  )
}

export default AlACarteForm