import React from "react"
import { Helmet } from "react-helmet"

interface SeoProps {
  storyblokSeo: StoryblokRef.Seo,
  lang?: string,
  pageName: string
}

function Seo({ storyblokSeo, lang, pageName }:SeoProps):JSX.Element {
  let seoData = storyblokSeo || {}
  const title =  seoData.title || `${pageName} | Petersen Asphalt Research Conference`,
        description = seoData.description || ""

  return (
    <Helmet>
      {/* Universal Values */}
      <html lang={lang || "en"} />
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Open Graph */}
      <meta name="og:title" content={seoData.og_title || seoData.title || title} />
      <meta name="og:description" content={seoData.og_description || seoData.description || description} />
      {seoData.og_image ? <meta name="og:image" content={seoData.og_image} /> : null}
      {/* Twitter */}
      {seoData.twitter_title ? <meta name="twitter:title" content={seoData.twitter_title} /> : null}
      {seoData.twitter_description ? <meta name="twitter:description" content={seoData.twitter_description} /> : null}
      {seoData.twitter_image ? <meta name="twitter:image" content={seoData.twitter_image} /> : null}
      {seoData.twitter_title ? <meta name="twitter:card" content="summary" /> : null}
    </Helmet>
  )
}

export default Seo