import React, { createRef, useEffect, useState } from "react"
import { Richtext } from "storyblok-js-client"

import { FormatCurrency } from "../../../utils/utils"
import { resolveRichText } from "../../../utils/richText"
import useStripeItems, { StripeItem } from "../../../hooks/useStripeItems"
import AsideWrapper, { AsideLeft, AsideRight } from "../../internal/asideWrapper/AsideWrapper"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"

// @ts-ignore
import * as formStyles from "../formStyles.module.css"

interface SponsorCheckboxItem {
  key: string,
  sponsor: StripeItem,
  isChecked: boolean
}

interface SponsorshipFormPanel extends Panel {
  sponsorship_items: Array<string>,

  success_redirect_to: StoryblokRef.Link,
  cancel_redirect_to: StoryblokRef.Link,

  form_footer_text: Richtext
}
interface SponsorshipFormProps {
  blok: SponsorshipFormPanel
}
function SponsorshipForm({ blok }:SponsorshipFormProps):JSX.Element {

  const stripeItems = useStripeItems()

  const [isLoading, setIsLoading] = useState<boolean>(false),
        [errorMessage, setErrorMessage] = useState<string>(""),
        firstNameRef = createRef<HTMLInputElement>(),
        lastNameRef = createRef<HTMLInputElement>(),
        affiliationRef = createRef<HTMLInputElement>(),
        address1Ref = createRef<HTMLInputElement>(),
        address2Ref = createRef<HTMLInputElement>(),
        cityRef = createRef<HTMLInputElement>(),
        stateRef = createRef<HTMLInputElement>(),
        zipRef = createRef<HTMLInputElement>(),
        countryRef = createRef<HTMLInputElement>(),
        telephoneRef = createRef<HTMLInputElement>(),
        emailRef = createRef<HTMLInputElement>(),

        sponsorNameRef = createRef<HTMLInputElement>(),
        sponsorWebsiteRef = createRef<HTMLInputElement>(),

        [sponsorItems, setSponsorItems] = useState<Array<SponsorCheckboxItem>>([])

  useEffect(()=>{
    let filtered = stripeItems.filter((item)=>{
      return blok.sponsorship_items.indexOf(item.uuid) != -1 ? true : false
    })
    let mapped = filtered.map((item)=>{
      let d:SponsorCheckboxItem = {
        key: item.uuid,
        sponsor: item,
        isChecked: false
      }
      return d
    })
    let sorted = mapped.sort((a,b)=>{
      if(a.sponsor.amount === b.sponsor.amount) {
        return 0
      }
      return a.sponsor.amount > b.sponsor.amount ? -1 : 1
    })
    let hash = window.location.hash
    if(hash) {
      hash = decodeURIComponent(hash.substring(1))
    }
    setSponsorItems(sorted.map((item)=>{
      return {
        ...item,
        isChecked: item.sponsor ? item.sponsor.name === hash : false
      }
    }))

    if(window && window.location && window.location.hash) {
      let timeout = setTimeout(()=>{
        window.scrollTo(0,0)
      }, 10)
    }
  },[])

  function handleSponsorItemChecked(key:string, checked:boolean) {
    setSponsorItems(sponsorItems.map((item)=>{
      if(item.key === key) {
        return {
          ...item,
          isChecked: checked
        }
      }
      return item
    }))
  }

  function ValidateForm(data:SponsorRegistrationRecord):string|boolean {
    if(!data.firstName) {
      return "Please provide a valid first name"
    }
    if(!data.lastName) {
      return "Please provide a valid last name"
    }
    if(!data.affiliation) {
      return "Please provide a valid affiliation"
    }
    if(!data.address1) {
      return "Please provide a valid address"
    }
    if(!data.city) {
      return "Please provide a valid city"
    }
    if(!data.state) {
      return "Please provide a valid state"
    }
    if(!data.country) {
      return "Please provide a valid country"
    }
    if(!data.telephone) {
      return "Please provide a valid telephone number"
    }
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if(!data.email || !emailPattern.test(data.email || "")) {
      return "Please provide a valid email"
    }

    if(!data.sponsor_name) {
      return "Please provide a sponsor name"
    }
    if(!data.sponsor_website) {
      return "Please provide a sponsor website"
    }
    if(!data.purchase_items || data.purchase_items.length < 1) {
      return "Please select a sponsorship option"
    }

    return true
  }

  function handleSubmitPressed(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const sponsorRecord:SponsorRegistrationRecord = {
      form_type: 'sponsor',
      firstName: `${firstNameRef.current?.value}`.trim(),
      lastName: `${lastNameRef.current?.value}`.trim(),
      affiliation: `${affiliationRef.current?.value}`.trim(),
      address1: `${address1Ref.current?.value}`.trim(),
      address2: `${address2Ref.current?.value}`.trim(),
      city: `${cityRef.current?.value}`.trim(),
      state: `${stateRef.current?.value}`.trim(),
      zip: `${zipRef.current?.value}`.trim(),
      country: `${countryRef.current?.value}`.trim(),
      telephone: `${telephoneRef.current?.value}`.trim(),
      email: `${emailRef.current?.value}`.trim(),

      sponsor_name: `${sponsorNameRef.current?.value}`.trim(),
      sponsor_website: `${sponsorWebsiteRef.current?.value}`.trim(),

      purchase_items: sponsorItems
        .filter((item)=>{return item.isChecked ? true : false})
        .map((item)=>{
          console.log("COST: ", typeof item.sponsor.amount, item.sponsor.amount)
          return {
            key: item.sponsor.uuid,
            quantity: 1,
            label: item.sponsor.name,
            price: item.sponsor.amount,
            stripe_price_id: item.sponsor.stripe_price_id
          }
        })
    }

    const validateResults = ValidateForm(sponsorRecord)
    if(validateResults === true) {
      const data:any = {
        form: 'SPONSOR_FORM',
        sponsor: sponsorRecord,
        success_url: blok.success_redirect_to ? blok.success_redirect_to.cached_url : '',
        cancel_url: blok.cancel_redirect_to ? blok.cancel_redirect_to.cached_url : '',
        return_domain: window.location.origin
      }

      setIsLoading(true)
      setErrorMessage("")

      fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/forms`, {
        method: 'POST',
        body: JSON.stringify(data)
      })
      .then((response)=>{
        response.text()
        .then((results)=>{
          // RESULTS
          console.log("SUCCESS", results)
          let resultData = JSON.parse(results)
          window.location.href = resultData.data.checkoutURL
        })
        .catch((err)=>{
          console.error(err)
          setErrorMessage("Failed to submit sponsor registration information")
        })
      })
      .catch((err)=>{
        console.error(err)
        setIsLoading(false)
        setErrorMessage("Failed to submit sponsor registration information")
      })
    } else {
      setIsLoading(false)
      setErrorMessage(`${validateResults}`)
      let ele = document.getElementById('error_message')
      if(ele) {
        ele.scrollIntoView()
      }
    }
  }

  return (
    <PanelWrapper blok={blok}>
      <AsideWrapper>
        <AsideRight></AsideRight>
        <AsideLeft>
          <form id="error_message" onSubmit={handleSubmitPressed}>
            <h3>Sponsorship Options</h3>
            {sponsorItems.map((item)=>(
              <div key={item.key} className="form-group text_box nomargintextbox">
                <input type="checkbox" checked={item.isChecked} onChange={(e)=>{handleSponsorItemChecked(item.key, e.target.checked)}} />
                <label>{`${item.sponsor.name} (${FormatCurrency(item.sponsor.amount)})`}</label>
              </div>
            ))}
            {/* *********** Contact Information *********** */}
            <h3>Your contact information</h3>
            <div style={errorMessage ? {display: 'block'} : {display: 'none'}}><p className={formStyles.errorMessage}>{errorMessage}</p></div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="first_name" required placeholder="First Name*" ref={firstNameRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="last_name" required placeholder="Last Name*" ref={lastNameRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="affiliation" required placeholder="Affiliation*" ref={affiliationRef} />
            </div>
            {/* Address */}
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="address_1" required placeholder="Address 1*" ref={address1Ref} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="address_2" placeholder="Address 2" ref={address2Ref} />
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group text_box">
                  <input disabled={isLoading} type="text" id="city" required placeholder="City*" ref={cityRef} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group text_box">
                  <input disabled={isLoading} type="text" id="state" required placeholder="State*" ref={stateRef} />
                </div>
              </div>
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="zip" placeholder="Zip" ref={zipRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="text" id="country" required placeholder="Country*" ref={countryRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="tel" id="telephone" required placeholder="Telephone*" ref={telephoneRef} />
            </div>
            <div className="form-group text_box">
              <input disabled={isLoading} type="email" id="email" required placeholder="Email*" ref={emailRef} />
            </div>
            {/* Sponsor Information */}
            <h3>Sponsorship details</h3>
            <div className="form-group text_box">
              <label htmlFor="sponsor_name">Name of sponsoring company*</label>
              <input disabled={isLoading} type="text" id="sponsor_name" required placeholder="Name*" ref={sponsorNameRef} />
            </div>
            <div className="form-group text_box">
              <label htmlFor="sponsor_website">Website address for sponsoring company*</label>
              <input disabled={isLoading} type="text" id="sponsor_website" required placeholder="https://www.petersenasphaltconference.org" ref={sponsorWebsiteRef} />
            </div>
            
            {blok.form_footer_text ? <div dangerouslySetInnerHTML={resolveRichText(blok.form_footer_text)} /> : null}
            
            <button className="parc-btn solid orange" disabled={isLoading} type="submit">Next</button>
          </form>
        </AsideLeft>
      </AsideWrapper>
    </PanelWrapper>
  )
}

export default SponsorshipForm