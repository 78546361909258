import React from "react"

import { FormatCurrency } from "../../../utils/utils"

interface BanquetDinnerSectionProps {
  isLoading:boolean,
  willAttendBanquet:boolean,
  setWillAttendBanquet:React.Dispatch<React.SetStateAction<boolean>>,
  banquetAdultMealsCount:number,
  setBanquetAdultMealsCount:React.Dispatch<React.SetStateAction<number>>,
  banquetChildMealsCount:number,
  setBanquetChildMealsCount:React.Dispatch<React.SetStateAction<number>>,

  adultMealCost:number,
  childMealCost:number
}
function BanquetDinnerSection({ isLoading, willAttendBanquet, setWillAttendBanquet, banquetAdultMealsCount, setBanquetAdultMealsCount, banquetChildMealsCount, setBanquetChildMealsCount, adultMealCost, childMealCost }:BanquetDinnerSectionProps):JSX.Element {

  function handleRadioToggleChanged(event:React.ChangeEvent<HTMLInputElement>) {
    setWillAttendBanquet(event.target.value === 'Yes' ? true : false)
    if(event.target.value === 'No') {
      setBanquetAdultMealsCount(0)
      setBanquetChildMealsCount(0)
    }
  }
  function handleCostNumberChanged(prop: string, event:React.ChangeEvent<HTMLInputElement>) {
    switch(prop) {
      case 'banquetAdultMealsCount':
        setBanquetAdultMealsCount(parseInt(event.target.value) || 0)
        break
      case 'banquetChildMealsCount':
        setBanquetChildMealsCount(parseInt(event.target.value) || 0)
        break
      default:
        console.warn(`Cost Property not handled [${prop}]`)
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">Will you be attending the <b>Banquet</b> on Wednesday?</div>
        <div className="col-lg-2 col-md-2 col-3">
          <input disabled={isLoading} type="radio" value="Yes" checked={willAttendBanquet === true} onChange={handleRadioToggleChanged} />Yes
        </div>
        <div className="col-lg-2 col-md-2 col-3">
          <input disabled={isLoading} type="radio" value="No" checked={willAttendBanquet === false} onChange={handleRadioToggleChanged} />No
        </div>
      </div>
      <div className="row" style={{marginTop: 10}}>
        <div className="col-lg-12" style={{marginBottom: 8}}>
          If you will have additional non-conference attendee guests joining you at the Banquet, you can purchase additional banquet meals below.
        </div>
        <div className="col-lg-6">
          <div className="form-group text_box">
            <label htmlFor="banquet_adult_meals">Adult Meals ({FormatCurrency(adultMealCost || 0)}/meal)</label>
            <input disabled={isLoading || willAttendBanquet === false} type="number" id="banquet_adult_meals" placeholder="Adult Meals" value={banquetAdultMealsCount} onChange={(e)=>{handleCostNumberChanged('banquetAdultMealsCount', e)}} />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group text_box">
            <label htmlFor="banquet_child_meals">Child Meals ({FormatCurrency(childMealCost || 0)}/meal)</label>
            <input disabled={isLoading || willAttendBanquet === false} type="number" id="banquet_child_meals" placeholder="Child Meals" value={banquetChildMealsCount} onChange={(e)=>{handleCostNumberChanged('banquetChildMealsCount', e)}} />
          </div>
        </div>
      </div>
    </div>
  )
}

function ValidateBanquetDinnerSectionValues(data:any):boolean|string {
  if(data.willAttendBanquet) {
    let count = parseInt(data.additionalBanquetAdultMeals)
    if(count < 0) {
      return "Please enter a valid number for additional adult meals"
    }
    count = parseInt(data.additionalBanquetChildMeals)
    if(count < 0) {
      return "Please enter a valid number for additional child meals"
    }
  }

  return true
}

export { ValidateBanquetDinnerSectionValues }
export default BanquetDinnerSection