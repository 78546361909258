import React, { useEffect, useState } from "react"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { Richtext } from "storyblok-js-client"

import { resolveRichText } from "../../../utils/richText"
import useConferencePresentations from "../../../hooks/useConferencePresentations"
import useConferenceSessions from "../../../hooks/useConferenceSessions"
import { GetDateFromStoryblokDate } from "../../../utils/utils"
// @ts-ignore
import * as styles from "./EventSchedulePanel.module.css"

interface ScheduleFilterButton {
  _uid: string,
  label: string,
  filter_date: number,
  datestring: string
}
interface EventSchedulePanel extends Panel {
  headline: Richtext,
  filter_buttons: Array<ScheduleFilterButton>
}
interface EventSchedulePanelProps {
  blok: EventSchedulePanel
}
function EventSchedule({ blok }:EventSchedulePanelProps):JSX.Element {
  const presentations = useConferencePresentations(),
        sessions = useConferenceSessions()
  // Some dates in this file are numeric while others are Date objects!
  const [currentFilterDate,setCurrentFilterDate] = useState<Date>()

  useEffect(()=>{
    if(blok.filter_buttons && blok.filter_buttons[0]) {
      let d = new Date(GetDateFromStoryblokDate(blok.filter_buttons[0].filter_date))
      setCurrentFilterDate(d)
    }
  },[blok])

  function onClickFilterButton(newFilterDate:number) {
    let d = GetDateFromStoryblokDate(newFilterDate)
    // let d = new Date(newFilterDate)
    setCurrentFilterDate(d)
  }
  
  const localeDateOptions:Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric'
  }
  const localeTimeOptions:Intl.DateTimeFormatOptions = {
    dateStyle: "long",
    timeStyle: "short"
  }
  
  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="container">
          <div className="hosting_title security_title text-center">
            <div className="wow fadeInUp" data-wow-delay="0.2s" dangerouslySetInnerHTML={resolveRichText(blok.headline)} />
          </div>
          <div className="event_schedule_inner">
            <ul className="nav nav-tabs event_tab" role="tablist">
              {blok.filter_buttons && blok.filter_buttons.length > 0 ? blok.filter_buttons.map((filter) => {

                let datestring = GetDateFromStoryblokDate(filter.filter_date).toLocaleDateString(undefined, localeDateOptions)
                return (
                  <li key={filter._uid} className="nav-item wow fadeInUp" data-wow-delay="0.4s" onClick={()=>{onClickFilterButton(filter.filter_date)}}>
                    <span className={`nav-link ${styles.link_item}${hasSameDate(GetDateFromStoryblokDate(filter.filter_date), currentFilterDate) ? " active" : ""}`} role="tab" aria-selected="true">
                      <h5>{filter.label}<span>{datestring}</span></h5>
                    </span>
                  </li>
                )
              }) : null}
            </ul>
            <div className="tab-content event_tab_content">
              <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">
                {presentations.filter((presentation)=>{
                  return hasSameDate(presentation.start_time, currentFilterDate)
                }).map((presentation)=>(
                  <div className="media" key={presentation.uuid}>
                    <div className="media-left">
                      <a href="#"><i className="icon_clock_alt" />{presentation.start_time.toLocaleString(undefined, localeTimeOptions)}</a>
                      {/* {presentation.location ? <a href="#"><i className="icon_pin_alt" />{presentation.location}</a> : null} */}
                    </div>
                    <div className="media-body" style={{marginTop: 12}}>
                      <h3 className="h_head">{presentation.title}</h3>
                      {presentation.moderator ? <span>{`Moderator: ${presentation.moderator}`}</span> : null}
                      {presentation.abstract ? <div dangerouslySetInnerHTML={resolveRichText(presentation.abstract)}></div> : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

function hasSameDate(a?:Date, b?:Date):boolean {
  if(a) {
    if(b) {
      if(a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear()) {
        return true
      }
    }
  }
  return false
}

export default EventSchedule