import { graphql, useStaticQuery } from "gatsby"
import { Richtext } from "storyblok-js-client"

import { GetDateFromStoryblokDate } from "../utils/utils"

interface ConferencePresentation {
  uuid: string,
  title: string,
  moderator?: string,
  start_time: Date,
  abstract?: Richtext,
  session?: string
}
function useConferencePresentations():Array<ConferencePresentation> {
  const results = useStaticQuery(graphql`
    query GetCurrentPresentations {
      allStoryblokEntry(
        filter: {field_component: {eq: "conference_presentation"}}
        limit: 9
      ) {
        edges {
          node {
            full_slug
            uuid
            content
          }
        }
      }
    }
  `)
  let presentations: Array<ConferencePresentation> = []

  results.allStoryblokEntry.edges.map((edge:any)=>{
    let data = JSON.parse(edge.node.content)
    presentations.push({
      uuid: edge.node.uuid,
      title: data.title,
      start_time: GetDateFromStoryblokDate(data.start_time),
      moderator: data.moderator,
      abstract: data.abstract,
      session: data.session
    })
  })

  return presentations
}

export { ConferencePresentation }
export default useConferencePresentations