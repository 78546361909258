import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import PriceItem, { PriceItemPanel } from "../../internal/priceItem/PriceItem"
import { resolveRichText } from "../../../utils/richText"
// @ts-ignore
import * as styles from "./PriceColumn.module.css"

interface PriceColumnPanel extends Panel {
  headline: Richtext,
  panel_footer: Richtext,
  items: Array<PriceItemPanel>
}
interface PriceColumnPanelProps {
  blok: PriceColumnPanel
}
function PriceColumn({ blok }:PriceColumnPanelProps):JSX.Element {
  return (
    <PanelWrapper blok={blok}>
      <section className="s_pricing_area">
        <div className="container custom_container">
          <div className="sec_title text-center mb_70" dangerouslySetInnerHTML={resolveRichText(blok.headline)} />
          <div className="row">
            {blok.items ? blok.items.map((item)=>{
              return (
                <PriceItem key={item._uid} blok={item} />
              )
            }) : null}
          </div>
          {blok.panel_footer ? <div className={`text-center ${styles.footer}`} dangerouslySetInnerHTML={resolveRichText(blok.panel_footer)} /> : null}
        </div>
      </section>
    </PanelWrapper>
  )
}

export default PriceColumn