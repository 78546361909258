import { graphql, useStaticQuery } from "gatsby"

interface Sponsor {
  _uid: string,
  image: StoryblokRef.Image,
  link: StoryblokRef.Link,
  sponsorship_level: string,
  hide_from_sponsorship_panel: boolean,
  sponsorship_panel_preferred_order: number
}
function useSponsors():Array<Sponsor> {
  const results = useStaticQuery(graphql`
    query GetSponsors {
      allStoryblokEntry(
        filter: {field_component: {eq: "sponsor"}}
      ) {
        edges {
          node {
            full_slug
            uuid
            content
          }
        }
      }
    }
  `)
  let sponsors: Array<Sponsor> = []

  results.allStoryblokEntry.edges.map((edge:any)=>{
    let data = JSON.parse(edge.node.content)
    sponsors.push({
      _uid: data._uid,
      link: data.link,
      image: data.image,
      sponsorship_level: data.sponsorship_level,
      hide_from_sponsorship_panel: data.hide_from_sponsorship_panel,
      sponsorship_panel_preferred_order: data.sponsorship_panel_preferred_order
    })
  })

  return sponsors
}

export { Sponsor }
export default useSponsors