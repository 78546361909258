import React from "react"

import StoryblokImage from "../storyblokImage/StoryblokImage"
import StoryblokLink from "../storyblokLink/StoryblokLink"

// @ts-ignore
import * as styles from "./EventSponsorLogo.module.css"

interface EventSponsorLogoItem {
  _uid?: string,
  image: StoryblokRef.Image,
  link: StoryblokRef.Link
  newWindow?: boolean
}
function EventSponsorLogo({ image, link, newWindow=false }:EventSponsorLogoItem):JSX.Element {
  return (
    <div className={`col-lg-3 col-sm-4 col-6 ${styles.sponsorItem}`}>
      <StoryblokLink withClass="analytices_logo wow fadeInUp" link={link} newWindow={newWindow}>
        <StoryblokImage image={image} />
      </StoryblokLink>
    </div>
  )
}

export { EventSponsorLogoItem }
export default EventSponsorLogo