import React from "react"

interface WelcomeReceptionSectionProps {
  isLoading:boolean,

  welcomeReceptionCount:number,
  setWelcomeReceptionCount:React.Dispatch<React.SetStateAction<number>>,
  willAttendWelcomeReception:boolean,
  setWillAttendWelcomeReception:React.Dispatch<React.SetStateAction<boolean>>
}
function WelcomeReceptionSection({ isLoading, welcomeReceptionCount, setWelcomeReceptionCount, willAttendWelcomeReception, setWillAttendWelcomeReception }:WelcomeReceptionSectionProps):JSX.Element {

  function handleRadioToggleChanged(event:React.ChangeEvent<HTMLInputElement>) {
    setWillAttendWelcomeReception(event.target.value === 'Yes' ? true : false)
    if(event.target.value === 'No') {
      setWelcomeReceptionCount(0)
    }
  }

  function handleCostNumberChanged(event:React.ChangeEvent<HTMLInputElement>) {
    setWelcomeReceptionCount(parseInt(event.target.value) || 0)
  }

  return (
    <div>
      {/* Welcome Reception */}
      <div className="row">
        <div className="col-lg-12">
          Will you be attending the <b>Welcome Reception</b> on Monday?
        </div>
        <div className="col-lg-2 col-md-2 col-3">
          <input disabled={isLoading} type="radio" value="Yes" checked={willAttendWelcomeReception === true} onChange={handleRadioToggleChanged} />Yes
        </div>
        <div className="col-lg-2 col-md-2 col-3">
          <input disabled={isLoading} type="radio" value="No" checked={willAttendWelcomeReception === false} onChange={handleRadioToggleChanged} />No
        </div>
      </div>
      <div className="row" style={{marginTop: 10}}>
        <div className="col-lg-12" style={{marginBottom: 8}}>
          If yes, how many non-conference attendee guests will be attending with you?
        </div>
        <div className="col-lg-6">
          <div className="text_box">
            <input disabled={isLoading || willAttendWelcomeReception === false} type="number" required value={welcomeReceptionCount} onChange={handleCostNumberChanged} />
          </div>
        </div>
      </div>
    </div>
  )
}

function ValidateWelcomeReceptionSectionValues(data:any):boolean|string {

  if(data.willAttendBanquet) {
    let count = parseInt(data.additionalWelcomeReceptionGuestsCount)
    if(count < 0) {
      return "Please enter a valid number for additional welcome reception guests"
    }
  }

  return true
}

export { ValidateWelcomeReceptionSectionValues }
export default WelcomeReceptionSection