import React from "react"

// @ts-ignore
import * as styles from "./PanelWrapper.module.css"
import { BackgroundKeyToColor, HeightSizeKeyToBarHeight, GeneralSizeKeyToPaddingNumber } from "../../../utils/datasources"

interface PanelWrapperProps {
  blok: Panel,
  children: any
}
function PanelWrapper({ blok, children }:PanelWrapperProps):JSX.Element {
  const backgroundColor = BackgroundKeyToColor(blok.background_color),
        barTopColor     = BackgroundKeyToColor(blok.bar_color_top),
        barBottomColor  = BackgroundKeyToColor(blok.bar_color_bottom),
        barTopSize      = HeightSizeKeyToBarHeight(blok.bar_height_top),
        barBottomSize   = HeightSizeKeyToBarHeight(blok.bar_height_bottom),
        paddingTop      = GeneralSizeKeyToPaddingNumber(blok.padding_top),
        paddingBot      = GeneralSizeKeyToPaddingNumber(blok.padding_bottom)
  
  return (
    <div className={styles.container} style={{paddingTop: paddingTop, paddingBottom: paddingBot, backgroundColor: backgroundColor}} id={blok.anchor_id}>
      <div className={styles.content}>
        {children}
      </div>
      {blok.bar_height_top || blok.bar_color_top ? <div className={styles.topbar} style={{height: barTopSize, backgroundColor: barTopColor}}></div> : null}
      {blok.bar_height_bottom || blok.bar_color_bottom ? <div className={styles.bottombar} style={{height: barBottomSize, backgroundColor: barBottomColor}}></div> : null}
    </div>
  )
}

export default PanelWrapper