import { graphql, useStaticQuery } from "gatsby"

interface SponsorshipLevel {
  _uid: string,
  uuid: string,
  name: string,
  order: number,
  hide_from_sponsorship_panel: boolean
}
function useSponsorshipLevels():Array<SponsorshipLevel> {
  const results = useStaticQuery(graphql`
    query GetSponsorshipLevels {
      allStoryblokEntry(
        filter: {field_component: {eq: "sponsorship_level"}}
      ) {
        edges {
          node {
            full_slug
            uuid
            content
          }
        }
      }
    }
  `)
  let sponsorshipLevels: Array<SponsorshipLevel> = []

  results.allStoryblokEntry.edges.map((edge:any)=>{
    let data = JSON.parse(edge.node.content)
    sponsorshipLevels.push({
      _uid: data._uid,
      uuid: edge.node.uuid,
      name: data.name,
      order: data.order,
      hide_from_sponsorship_panel: data.hide_from_sponsorship_panel
    })
  })

  return sponsorshipLevels.sort(SortSponsorshipLevels)
}

function SortSponsorshipLevels(a:SponsorshipLevel,b:SponsorshipLevel):number {
  if(a.order === b.order) {
    return 0
  } else {
    return a.order > b.order ? 1 : -1
  }
}

export { SponsorshipLevel }
export default useSponsorshipLevels