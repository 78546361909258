import React from "react"

interface ContactMiniSectionProps {
  isLoading:boolean,

  firstNameRef:React.RefObject<HTMLInputElement>,
  lastNameRef:React.RefObject<HTMLInputElement>,
  affiliationRef:React.RefObject<HTMLInputElement>,
  telephoneRef:React.RefObject<HTMLInputElement>,
  emailRef:React.RefObject<HTMLInputElement>
}
function ContactMiniSection({ isLoading, firstNameRef, lastNameRef, affiliationRef, telephoneRef, emailRef }:ContactMiniSectionProps):JSX.Element {


  return (
    <div>
      {/* *********** Contact Information *********** */}
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="first_name" required placeholder="First Name*" ref={firstNameRef} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="last_name" required placeholder="Last Name*" ref={lastNameRef} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="text" id="affiliation" required placeholder="Affiliation*" ref={affiliationRef} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="tel" id="telephone" required placeholder="Telephone*" ref={telephoneRef} />
      </div>
      <div className="form-group text_box">
        <input disabled={isLoading} type="email" id="email" required placeholder="Email*" ref={emailRef} />
      </div>
    </div>
  )
}

///
/// The validate function will return true if it is valid, otherwise a message as to why it failed
///
function ValidateContactMiniSectionValues(data:any):boolean|string {
  if(!data.firstName) {
    return "Please provide a valid first name"
  }
  if(!data.lastName) {
    return "Please provide a valid last name"
  }
  if(!data.affiliation) {
    return "Please provide a valid affiliation"
  }
  if(!data.telephone) {
    return "Please provide a valid telephone number"
  }
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  if(!data.email || !emailPattern.test(data.email || "")) {
    return "Please provide a valid email"
  }

  return true
}

export { ValidateContactMiniSectionValues }
export default ContactMiniSection