import React, { useState, createRef, useEffect } from "react"

import ContactSection from "../../formSections/contactSection/ContactSection"
import { ValidateContactSectionValues } from "../../formSections/contactSection/ContactSection"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"

// @ts-ignore
import * as formStyles from "../formStyles.module.css"


interface AbstractFormPanel extends Panel {
  submit_text: string,
  
  thank_you_url: StoryblokRef.Link
}
interface AbstractFormProps {
  blok: AbstractFormPanel
}

function AbstractForm({ blok }:AbstractFormProps):JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false),
        [errorMessage, setErrorMessage] = useState<string>(""),
        firstNameRef = createRef<HTMLInputElement>(),
        lastNameRef = createRef<HTMLInputElement>(),
        affiliationRef = createRef<HTMLInputElement>(),
        address1Ref = createRef<HTMLInputElement>(),
        address2Ref = createRef<HTMLInputElement>(),
        cityRef = createRef<HTMLInputElement>(),
        stateRef = createRef<HTMLInputElement>(),
        zipRef = createRef<HTMLInputElement>(),
        countryRef = createRef<HTMLInputElement>(),
        telephoneRef = createRef<HTMLInputElement>(),
        emailRef = createRef<HTMLInputElement>(),

        presenterRef = createRef<HTMLInputElement>(),
        deliveryRef = createRef<HTMLSelectElement>(),
        authorsRef = createRef<HTMLInputElement>(),
        abstractTitleRef = createRef<HTMLInputElement>(),
        [abstract,setAbstract] = useState<string>("")

  const wordCount:number = abstract.split(' ').filter((word)=>{return word ? true : false}).length

  function ValidateForm(data:AbstractRegistrationRecord):string|boolean {
    let contactValidation = ValidateContactSectionValues(data)
    if(typeof contactValidation === "string") {
      return contactValidation
    }

    if(!data.delivery_option) {
      return "Please select a delivery option"
    }


    if(!data.presenter) {
      return "Please enter a presenter name"
    }

    if(!data.abstract_title) {
      return "Please enter an abstract title"
    }

    if(!data.abstract) {
      return "Please enter your abstract"
    }

    if(wordCount > 220) {
      return "Your abstract is too long. It should be 200 words or less."
    }

    return true
  }

  function handleSubmitPressed(event:React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    const abstractRecord:AbstractRegistrationRecord = {
      firstName: `${firstNameRef.current?.value}`.trim(),
      lastName: `${lastNameRef.current?.value}`.trim(),
      affiliation: `${affiliationRef.current?.value}`.trim(),
      address1: `${address1Ref.current?.value}`.trim(),
      address2: `${address2Ref.current?.value}`.trim(),
      city: `${cityRef.current?.value}`.trim(),
      state: `${stateRef.current?.value}`.trim(),
      zip: `${zipRef.current?.value}`.trim(),
      country: `${countryRef.current?.value}`.trim(),
      telephone: `${telephoneRef.current?.value}`.trim(),
      email: `${emailRef.current?.value}`.trim(),

      presenter: `${presenterRef.current?.value}`.trim(),
      delivery_option: `${deliveryRef.current?.value}`.trim(),
      abstract_title: `${abstractTitleRef.current?.value}`.trim(),
      authors: `${authorsRef.current?.value}`.trim(),
      abstract: `${abstract}`.trim()
    }

    const validateResults = ValidateForm(abstractRecord)
    if(validateResults === true) {
      const data:any = {
        form: 'ABSTRACT_FORM',
        abstract: abstractRecord,
      }

      setIsLoading(true)
      setErrorMessage("")

      fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/forms`, {
        method: "POST",
        body: JSON.stringify(data)
      })
      .then((response)=>{
        response.text()
        .then((results)=>{
          if(blok.thank_you_url && blok.thank_you_url.cached_url) {
            const responseData = JSON.parse(results)
            let clientId = responseData.data.client_reference_id
            window.location.href = `${window.location.origin}/${blok.thank_you_url.cached_url}${clientId ? `?c=${clientId}` : ''}`
          }
        })
        .catch((err)=>{
          setIsLoading(false)
          setErrorMessage("Failed to submit your abstract")
        })
      })
      .catch((err)=>{
        setIsLoading(false)
        setErrorMessage("Failed to submit your abstract")
      })
    } else {
      setIsLoading(false)
      setErrorMessage(`${validateResults}`)
      let ele = document.getElementById('error_message')
      if(ele) {
        ele.scrollIntoView()
      }
    }
  }

  function handleAbstractUpdate(e:React.ChangeEvent<HTMLTextAreaElement>) {
    setAbstract(e.target.value)
  }

  return (
    <PanelWrapper blok={blok}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <form id="error_message" onSubmit={handleSubmitPressed}>
              {/* *********** Contact Information *********** */}
              <h3>Your contact information</h3>
              <div style={errorMessage ? {display: 'block'} : {display: 'none'}}><p className={formStyles.errorMessage}>{errorMessage}</p></div>
              <ContactSection
                isLoading={isLoading}
                firstNameRef={firstNameRef}
                lastNameRef={lastNameRef}
                affiliationRef={affiliationRef}
                address1Ref={address1Ref}
                address2Ref={address2Ref}
                cityRef={cityRef}
                stateRef={stateRef}
                zipRef={zipRef}
                countryRef={countryRef}
                telephoneRef={telephoneRef}
                emailRef={emailRef} />

              {/* Abstract Information */}
              <h3>Abstract Information</h3>
              <fieldset className="form-group text_box">
                <label htmlFor="presenter">Presenter</label>
                <input disabled={isLoading} type="text" id="presenter" placeholder="Presenter" ref={presenterRef} />
              </fieldset>

              <fieldset className="form-group text_box">
                <label htmlFor="delivery_option">How will you like to deliver your presentation?</label>
                <select id="delivery_option" className="registration-select" ref={deliveryRef}>
                  <option>Select an Option</option>
                  <option value="Lectern/Poster">Lectern/Poster</option>
                  <option value="Poster Only">Poster Only</option>
                </select>
              </fieldset>
              
              <fieldset className="form-group text_box">
                <label htmlFor="abstract_title">Abstract Title</label>
                <input disabled={isLoading} type="text" id="abstract_title" placeholder="Abstract Title" ref={abstractTitleRef} />
              </fieldset>
              <fieldset className="form-group text_box">
                <label htmlFor="authors">Authors (Please include any affiliations with each person)</label>
                <input disabled={isLoading} type="text" id="authors" placeholder="Authors" ref={authorsRef} />
              </fieldset>
              <fieldset className="form-group text_box">
                <label htmlFor="abstract">{wordCount > 0 ? `Abstract (${wordCount}/200 Words)` : `Abstract (200 Words or less)`}</label>
                <textarea disabled={isLoading} rows={4} id="abstract" placeholder="Abstract" onChange={handleAbstractUpdate} value={abstract}></textarea>
              </fieldset>

              <button className="parc-btn solid orange" disabled={isLoading} type="submit">{blok.submit_text}</button>
            </form>
          </div>
        </div>
      </div>
    </PanelWrapper>
  )
}

export default AbstractForm