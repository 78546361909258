import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../utils/richText"
// @ts-ignore
import * as styles from "./EventPriceArea.module.css"


interface EventPricePanel extends Panel {
  headline: Richtext
}
interface EventPriceAreaProps {
  blok: EventPricePanel
}
function EventPriceArea({ blok }:EventPriceAreaProps):JSX.Element {
  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="container">
          <div className="hosting_title security_title text-center" dangerouslySetInnerHTML={resolveRichText(blok.headline)} />
          <div className="row align-items-center">
            <div className="col-lg-4 col-sm-6">
              <div className={`analytices_price_item event_price_item wow fadeInUp ${styles.bottom_design}`}>
                <div className="p_head">
                  <h5>One day Pass</h5>
                  <div className="rate">$59.99</div>
                </div>
                <ul className="list-unstyled p_body">
                  <li>
                    All Lite features <i className="ti-check"></i>
                  </li>
                  <li>
                    Unlimited contacts <i className="ti-check"></i>
                  </li>
                  <li>
                    Remove Sendinblue <i className="ti-close"></i>
                  </li>
                  <li>
                    logo from emails <i className="ti-close"></i>
                  </li>
                  <li>
                    Advanced statistics <i className="ti-check"></i>
                  </li>
                </ul>
                <div className="text-center">
                  <a href="/#" className="event_btn event_btn_two btn_hover">
                    Buy Ticket
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div
                className={`analytices_price_item event_price_item wow fadeInUp ${styles.bottom_design}`}
                data-wow-delay="0.2s"
              >
                <div className="p_head">
                  <h5>Starter</h5>
                  <h6 className="tag">
                    <i className="icon_ribbon_alt"></i>Most popular
                  </h6>
                  <div className="rate">$59.99</div>
                </div>
                <ul className="list-unstyled p_body">
                  <li>
                    All Lite features <i className="ti-check"></i>
                  </li>
                  <li>
                    Unlimited contacts <i className="ti-check"></i>
                  </li>
                  <li>
                    Remove Sendinblue <i className="ti-close"></i>
                  </li>
                  <li>
                    logo from emails <i className="ti-close"></i>
                  </li>
                  <li>
                    Advanced statistics <i className="ti-check"></i>
                  </li>
                </ul>
                <div className="text-center">
                  <a href="/#" className="event_btn btn_hover">
                    Buy Ticket
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div
                className={`analytices_price_item event_price_item wow fadeInUp ${styles.bottom_design}`}
                data-wow-delay="0.4s"
              >
                <div className="p_head">
                  <h5>VIP Seat</h5>
                  <div className="rate">$159.99</div>
                </div>
                <ul className="list-unstyled p_body">
                  <li>
                    All Lite features <i className="ti-check"></i>
                  </li>
                  <li>
                    Unlimited contacts <i className="ti-check"></i>
                  </li>
                  <li>
                    Remove Sendinblue <i className="ti-close"></i>
                  </li>
                  <li>
                    logo from emails <i className="ti-close"></i>
                  </li>
                  <li>
                    Advanced statistics <i className="ti-check"></i>
                  </li>
                </ul>
                <div className="text-center">
                  <a href="/#" className="event_btn event_btn_two btn_hover">
                    Buy Ticket
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

export default EventPriceArea