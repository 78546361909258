import React, { useEffect, useState } from "react"

// @ts-ignore
import * as styles from "./ExhibitorRegistrationCompleteSummaryPanel.module.css"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { FormatCurrency } from "../../../utils/utils"

interface ExhibitorRegistrationCompletePanel extends Panel {}

interface ExhibitorRegistrationCompleteProps {
  blok: ExhibitorRegistrationCompletePanel
}

function ExhibitorRegistrationCompleteSummaryPanel({ blok }:ExhibitorRegistrationCompleteProps):JSX.Element {
  const [isLoading,setIsLoading] = useState<boolean>(true)
  const [registrationDetails,setRegistrationDetails] = useState<ExhibitorRegistrationRecord>()

  function fetchExhibitorData() {
    let urlParams = new URLSearchParams(window.location.search)
    const customer_reference_id = urlParams.get('c')


    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/forms?c=${customer_reference_id}`, {
      method: 'GET',
    })
      .then((response)=>{
        response.text().then((responseString)=>{
          const d = JSON.parse(responseString)

          setRegistrationDetails(d.data.data)
          setIsLoading(false)
        })
        .catch((err)=>{
          console.error(err)
        })
      })
      .catch((err)=>{
        console.error(err)
      })
  }

  useEffect(()=>{
    fetchExhibitorData()
  },[])


  let items:Array<string> = []
  if(registrationDetails) {
    registrationDetails.purchase_items?.map((item)=>{
      if(item) {
        items.push(`  ${item.label} - (${item.quantity} @ ${FormatCurrency(item.price)})`)
      }
    })
  }

  return (
    <PanelWrapper blok={blok}>
      {isLoading ? (
        <div className={`container ${styles.loading}`}>
          Please wait while your order summary is loaded.
        </div>
      ) : (
        <div className="container">
          <h3>Contact Information</h3>
          <div>{`${registrationDetails?.firstName} ${registrationDetails?.lastName}`}</div>
          <div>Affiliation: {registrationDetails?.affiliation}</div>
          <div>{`${registrationDetails?.address1} ${registrationDetails?.address2}`}</div>
          <div>{`${registrationDetails?.city}, ${registrationDetails?.state}, ${registrationDetails?.zip}, ${registrationDetails?.country}`}</div>
          <div>Telephone: {registrationDetails?.telephone}</div>
          <div>Email: {registrationDetails?.email}</div>
          <br/>
          <h3>Exhibitor Information</h3>
          <div>{`Name: ${registrationDetails?.exhibitor_first_name} ${registrationDetails?.exhibitor_last_name}`}</div>
          <div>Will Attend Welcome Reception: {registrationDetails?.willAttendBanquet ? "Yes" : "No"}</div>
          <div>Additional Reception Guests: {registrationDetails?.additionalWelcomeReceptionGuestsCount || 0}</div>
          <div>Will Attend Banquet: {registrationDetails?.willAttendBanquet ? "Yes" : "No"}</div>

          <br/>
          <div>
            <div>---------------------</div>
            {items.map(((item,idx)=>(
              <div key={idx}>{item}</div>
            )))}
            <div>{`Total Charged: ${FormatCurrency(registrationDetails?.amount_charged || 0)}`}</div>
            <div>---------------------</div>
          </div>        </div>
      )}
    </PanelWrapper>
  )
}

export default ExhibitorRegistrationCompleteSummaryPanel