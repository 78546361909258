import React from "react"
import { Richtext } from "storyblok-js-client"

import { resolveRichText } from "../../../utils/richText"
import StoryblokImage from "../storyblokImage/StoryblokImage"
import StoryblokLink from "../storyblokLink/StoryblokLink"
// @ts-ignore
import * as styles from "./EventFeaturesItem.module.css"

interface EventFeaturesItemProps {
  _uid?: string,
  cta_link: StoryblokRef.Link,
  title: string,
  content: Richtext,
  cta_label: string,
  image: StoryblokRef.Image
}
function EventFeaturesItem({cta_link, title, content, cta_label, image}:EventFeaturesItemProps):JSX.Element {
  return (
    <div className={`col-lg-4 col-sm-6 ${styles.item_wrapper}`}>
      <div className="event_features_item text-center wow fadeInUp">
        {image ? <StoryblokImage image={image} /> : null}
        <h5>{title}</h5>
        {content ? <div dangerouslySetInnerHTML={resolveRichText(content)} /> : null}
        {cta_link ? <StoryblokLink link={cta_link} withClass={`e_features_btn ${styles.item_link}`}>{cta_label}</StoryblokLink> : <span className={`e_features_btn ${styles.item_link}`}>{cta_label}</span>}
      </div>
    </div>
  )
}

export { EventFeaturesItemProps as EventFeatureItem }
export default EventFeaturesItem