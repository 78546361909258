import React from "react"
import { Richtext } from "storyblok-js-client"

import { resolveRichText } from "../../../utils/richText"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"

interface AsideTextPanel extends Panel {
  content: Richtext,
  aside_content: Richtext,
  mobile_content_below: boolean
}
interface AsideTextPanelProps {
  blok: AsideTextPanel
}
function AsideText({ blok }:AsideTextPanelProps):JSX.Element {
  let asideContent = (
    <div className="parc-aside-left-padding">
      <div dangerouslySetInnerHTML={resolveRichText(blok.aside_content)} className="basic-rich-text-fix" />
    </div>
  )
  return (
    <PanelWrapper blok={blok}>
      <div className="container">
        <div className="row">
          <div className={`${blok.mobile_content_below ? "order-last" : "order-first"} col-lg-4 order-lg-last`}>
            {asideContent}
          </div>
          <div className="col-lg-8">
            <div dangerouslySetInnerHTML={resolveRichText(blok.content)} />
          </div>
        </div>
      </div>
    </PanelWrapper>
  )
}

export default AsideText