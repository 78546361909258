import React, { useState, useEffect } from "react"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
// @ts-ignore
import * as styles from "./AbstractRegistrationCompleteSummaryPanel.module.css"

interface AbstractRegistrationCompleteSummaryPanel extends Panel {}
interface AbstractRegistrationCompleteSummaryProps {
  blok: AbstractRegistrationCompleteSummaryPanel
}
function AbstractRegistrationCompleteSummary({ blok }:AbstractRegistrationCompleteSummaryProps):JSX.Element {
  const [isLoading,setIsLoading] = useState<boolean>(true)
  const [registrationDetails,setRegistrationDetails] = useState<AbstractRegistrationRecord>()

  function fetchAbstractData() {
    let urlParams = new URLSearchParams(window.location.search)
    const customer_reference_id = urlParams.get('c')

    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/forms?c=${customer_reference_id}`, {
      method: 'GET',
    })
      .then((response)=>{
        response.text().then((responseString)=>{
          const d = JSON.parse(responseString)

          setRegistrationDetails(d.data.data)
          setIsLoading(false)
        })
        .catch((err)=>{
          console.error(err)
        })
      })
      .catch((err)=>{
        console.error(err)
      })
  }

  useEffect(()=>{
    fetchAbstractData()
  },[])

  return (
    <PanelWrapper blok={blok}>
      {isLoading ? (
        <div className={`container ${styles.loading}`}>
          Please wait while your order summary is loaded.
        </div>
      ) : (
        <div className="container">
          <h3>Your contact information</h3>
          <div>{`${registrationDetails?.firstName} ${registrationDetails?.lastName}`}</div>
          <div>Affiliation: {registrationDetails?.affiliation}</div>
          <div>Telephone: {registrationDetails?.telephone}</div>
          <div>Email: {registrationDetails?.email}</div>

        </div>
      )}
    </PanelWrapper>
  )
}

export default AbstractRegistrationCompleteSummary