import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../utils/richText"
import StoryblokImage from "../../internal/storyblokImage/StoryblokImage"
// @ts-ignore
import * as styles from "./ImageText.module.css"

interface ImageTextPanel extends Panel {
  image: StoryblokRef.Image,
  text: Richtext,
  text_styles: Array<string>,
  image_side: string,
  image_width: string
}
interface ImageTextPanelProps {
  blok: ImageTextPanel
}

var breakpoints = {
  "-400":"500x500",
  "-768":"600x600",
  "-900":"700x700",
  "-1080":"800x800",
  "-1200":"900x900",
}

function ImageText({ blok }:ImageTextPanelProps):JSX.Element {
  const colSizes = widthToColSizes(blok.image_width)
  return (
    <PanelWrapper blok={blok}>
      <div className="container">
        <div className="row">
          <div className={`col-lg-${colSizes[0]} ${styles.imageWrapper}${blok.image_side==="right" ? " order-2" : ""}`}>
            {blok.image ? <StoryblokImage image={blok.image} sizes={breakpoints} /> : null}
          </div>
          <div className={`col-lg-${colSizes[1]}${blok.text_styles && blok.text_styles.length > 0 ? ` ${blok.text_styles.join(' ')}` : ""}`}>
            <div dangerouslySetInnerHTML={resolveRichText(blok.text)} />
          </div>
        </div>
      </div>
    </PanelWrapper>
  )
}

function widthToColSizes(width: string):Array<number> {
  switch(width) {
    case "4":
      return [4,8]
    case "3":
      return [3,9]
    default:
      return [6,6]
  }
}

export default ImageText