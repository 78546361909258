import React from "react"
import { Richtext } from "storyblok-js-client"

import { resolveRichText } from "../../../utils/richText"
import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
// @ts-ignore
import * as styles from "./Hero.module.css"

interface HeroPanel extends Panel {
  image: StoryblokRef.Image,
  content: Richtext,

  background_color_1: string,
  background_color_2: string
}
interface HeroPanelProps {
  blok: HeroPanel
}
function Hero({ blok }:HeroPanelProps):JSX.Element {
  const hasImage = blok.image && blok.image.filename ? true : false;
  const gradient = `linear-gradient(120deg, ${blok.background_color_1 || "#D85C22"} 0%, ${blok.background_color_2 || "#4B0000"} 75%)`
  return (
    <PanelWrapper blok={blok}>
      <section className={`breadcrumb_area ${hasImage ? styles.hero_image : ""}`} style={hasImage ? {backgroundImage: `url(${blok.image.filename}/m/1920x0)`} : {backgroundImage: gradient}}>
        {hasImage ? <div className={styles.underlay} /> : null}
        <div className={styles.hero_content}>
          <div className="container">
            <div className="breadcrumb_content text-center" dangerouslySetInnerHTML={resolveRichText(blok.content)} />
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

export default Hero