///
/// First function is the ones that get used in code
///
function GetEnvironmentKey(key, OVERRIDES) {
  if(OVERRIDES) {
    if(OVERRIDES.NETLIFY === "true" && OVERRIDES.CONTEXT === "production") {
      let value = GetLiveKey(key)
      if(!value) {
        console.warn('Environment key missing live value: ', key)
      }
      return value
    }
  } else {
    if(process.env.NETLIFY === "true" && process.env.CONTEXT === "production") {
      let value = GetLiveKey(key)
      if(!value) {
        console.warn('Environment key missing live value: ', key)
      }
      return value
    }
  }
  let value = GetTestingKey(key)
  if(!value) {
    console.warn('Environment key missing testing value: ', key)
  }
  return value
}

/// RETRIEVERS

// Keys that start with LIVE_. are only used in production live branch
function GetLiveKey(key) {
  switch(key) {
    case "FAUNA_API_SECRET":
      return process.env.LIVE_FAUNA_API_SECRET
    case "GOOGLE_MAPS_API":
      return process.env.GATSBY_GOOGLE_MAPS_API
    case "SENDGRID_API_KEY":
      return process.env.SENDGRID_API_KEY
    case "STORYBLOK_ACCESS_TOKEN":
      return process.env.GATSBY_STORYBLOK_ACCESS_TOKEN
    case "STRIPE_KEY":
      return process.env.LIVE_STRIPE_KEY
    default:
      return ""
  }
}
// Keys that start with TESTING_. are used in the preview branch and development environment
function GetTestingKey(key) {
  switch(key) {
    case "FAUNA_API_SECRET":
      return process.env.TESTING_FAUNA_API_SECRET
    case "GOOGLE_MAPS_API":
      return process.env.GATSBY_GOOGLE_MAPS_API
    case "SENDGRID_API_KEY":
      return process.env.SENDGRID_API_KEY
    case "STORYBLOK_ACCESS_TOKEN":
      return process.env.GATSBY_STORYBLOK_ACCESS_TOKEN
    case "STRIPE_KEY":
      return process.env.TESTING_STRIPE_KEY
    default:
      return ""
  }
}



module.exports = {
  GetEnvironmentKey
}