import React from "react"
import { Richtext } from "storyblok-js-client"

import PanelWrapper from "../../internal/panelWrapper/PanelWrapper"
import { resolveRichText } from "../../../utils/richText"
// @ts-ignore
import * as styles from "./FactAreaPanel.module.css"

interface FactItem {
  _uid: string,
  label: string,
  amount: number
}
interface FactAreaPanel extends Panel {
  headline: Richtext,
  facts: Array<FactItem>
}
interface FactAreaPanelProps {
  blok: any
}
function FactAreaPanel({ blok }:FactAreaPanelProps):JSX.Element {
  return (
    <PanelWrapper blok={blok}>
      <section>
        <div className="container">
          <div className="hosting_title security_title text-center" dangerouslySetInnerHTML={resolveRichText(blok.headline)} />
          <div className={`seo_fact_info ${styles.background}`}>
              {blok.facts.map((fact:FactItem, idx:number)=>(  
                <div key={fact._uid} className={`seo_fact_item wow fadeIn ${styles.fact_item}`}>
                  <div className="text">
                    <div className={`counter ${IdxToColorString(idx)}`}>{fact.amount}</div>
                    <p>{fact.label}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </PanelWrapper>
  )
}

function IdxToColorString(idx:number):string {
  switch(idx%4) {
    case 0:
      return "one"
    case 1:
      return "two"
    case 2:
      return "three"
    default:
      return "four"
  }
}

export default FactAreaPanel