import React from "react"
import { Map, GoogleApiWrapper, Marker } from "google-maps-react"
// @ts-ignore
import { GetEnvironmentKey } from "../../../../utils/env"

function MapContainer({ google, markers }) {
  return (
    <section className="contact_map">
        <Map
          google={google}
          zoom={12}
          initialCenter={markers && markers.length > 0 ? {lat: markers[0].latitude, lng: markers[0].longitude} : {lat: 0, lng: 0}}
          style={{width: "100%", height: "100%"}}
        >
          {markers ? markers.map((mark, index)=>{
            return (
              <Marker
                key={index}
                id={index}
                position={{
                  lat: mark.latitude,
                  lng: mark.longitude,
                }}
              />
            )
          }) : null}
        </Map>
      </section>
  )
}

export default GoogleApiWrapper({
  apiKey: GetEnvironmentKey("GOOGLE_MAPS_API"),
})(MapContainer);
