import React from "react"
import { Helmet } from "react-helmet"

import useStoryblok from "../lib/storyblok"

import { RenderComponents } from "../components/DynamicComponent"
import GlobalLayout from "../components/layout/globalLayout/GlobalLayout"
import Seo from "../components/internal/seo/Seo"


function Page({pageContext, location}:PageTemplateProps):JSX.Element {
  let story:any = pageContext ? pageContext.story : undefined
  story = useStoryblok(story, location)

  let mainContent:Array<JSX.Element> = []

  if(story && story.content && story.content.body) {
    mainContent = RenderComponents(story.content.body)
  }

  return (
    <GlobalLayout>
      {/* {process.env.CONTEXT !== "production" ? (
        <Helmet>
          <meta name="robots" content="noindex" />
          <meta name="robots" content="nofollow" />
        </Helmet>
      ) : null} */}
      <Seo storyblokSeo={story && story.content && story.content.seo ? story.content.seo : {}} pageName={story.name || ""} />
      {mainContent}
    </GlobalLayout>
  )
}

export default Page