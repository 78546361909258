import { graphql, useStaticQuery } from "gatsby"

interface RegistrationType {
  _uid: string,
  uuid: string,
  name: string,
  line_item_label: string,
  amount: number,
  stripe_price_id: string,
  preferred_dropdown_order?: number
}
function useRegistrationTypes() {
  const results = useStaticQuery(graphql`
    query GetRegistrationTypes {
      allStoryblokEntry(
        filter: {field_component: {eq: "registration_type"}}
      ) {
        edges {
          node {
            full_slug
            uuid
            content
          }
        }
      }
    }
  `)
  let types: Array<RegistrationType> = []

  results.allStoryblokEntry.edges.map((edge:any)=>{
    let data = JSON.parse(edge.node.content)
    types.push({
      _uid: data._uid,
      uuid: edge.node.uuid,
      name: data.name,
      line_item_label: data.line_item_label,
      amount: parseFloat(data.amount) || 0,
      stripe_price_id: data.stripe_price_id,
      preferred_dropdown_order: data.preferred_dropdown_order
    })
  })

  return types.sort(sortTypes)
}

function sortTypes(a:RegistrationType,b:RegistrationType) {
  if(a.preferred_dropdown_order == b.preferred_dropdown_order) {
    return 0
  }
  return (a.preferred_dropdown_order || 0) > (b.preferred_dropdown_order || 0) ? 1 : -1
}

export { RegistrationType }
export default useRegistrationTypes